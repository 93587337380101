import {
  __export
} from "./chunk-MLKGABMK.js";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/DocHandle.js
import * as A from "#automerge/next";
import debug from "debug";
import { EventEmitter } from "eventemitter3";

// ../../../../node_modules/.pnpm/xstate@5.12.0/node_modules/xstate/dev/dist/xstate-dev.esm.js
function getGlobal() {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
}
function getDevTools() {
  const w = getGlobal();
  if (!!w.__xstate__) {
    return w.__xstate__;
  }
  return void 0;
}
var devToolsAdapter = (service) => {
  if (typeof window === "undefined") {
    return;
  }
  const devTools = getDevTools();
  if (devTools) {
    devTools.register(service);
  }
};

// ../../../../node_modules/.pnpm/xstate@5.12.0/node_modules/xstate/dist/raise-040ba012.esm.js
var Mailbox = class {
  constructor(_process) {
    this._process = _process;
    this._active = false;
    this._current = null;
    this._last = null;
  }
  start() {
    this._active = true;
    this.flush();
  }
  clear() {
    if (this._current) {
      this._current.next = null;
      this._last = this._current;
    }
  }
  enqueue(event) {
    const enqueued = {
      value: event,
      next: null
    };
    if (this._current) {
      this._last.next = enqueued;
      this._last = enqueued;
      return;
    }
    this._current = enqueued;
    this._last = enqueued;
    if (this._active) {
      this.flush();
    }
  }
  flush() {
    while (this._current) {
      const consumed = this._current;
      this._process(consumed.value);
      this._current = consumed.next;
    }
    this._last = null;
  }
};
var STATE_DELIMITER = ".";
var TARGETLESS_KEY = "";
var NULL_EVENT = "";
var STATE_IDENTIFIER = "#";
var WILDCARD = "*";
var XSTATE_INIT = "xstate.init";
var XSTATE_STOP = "xstate.stop";
function createAfterEvent(delayRef, id) {
  return {
    type: `xstate.after.${delayRef}.${id}`
  };
}
function createDoneStateEvent(id, output) {
  return {
    type: `xstate.done.state.${id}`,
    output
  };
}
function createDoneActorEvent(invokeId, output) {
  return {
    type: `xstate.done.actor.${invokeId}`,
    output
  };
}
function createErrorActorEvent(id, error) {
  return {
    type: `xstate.error.actor.${id}`,
    error
  };
}
function createInitEvent(input) {
  return {
    type: XSTATE_INIT,
    input
  };
}
function reportUnhandledError(err) {
  setTimeout(() => {
    throw err;
  });
}
var symbolObservable = (() => typeof Symbol === "function" && Symbol.observable || "@@observable")();
function createScheduledEventId(actorRef, id) {
  return `${actorRef.sessionId}.${id}`;
}
var idCounter = 0;
function createSystem(rootActor, options) {
  const children = /* @__PURE__ */ new Map();
  const keyedActors = /* @__PURE__ */ new Map();
  const reverseKeyedActors = /* @__PURE__ */ new WeakMap();
  const inspectionObservers = /* @__PURE__ */ new Set();
  const timerMap = {};
  const {
    clock,
    logger
  } = options;
  const scheduler = {
    schedule: (source, target, event, delay, id = Math.random().toString(36).slice(2)) => {
      const scheduledEvent = {
        source,
        target,
        event,
        delay,
        id,
        startedAt: Date.now()
      };
      const scheduledEventId = createScheduledEventId(source, id);
      system._snapshot._scheduledEvents[scheduledEventId] = scheduledEvent;
      const timeout = clock.setTimeout(() => {
        delete timerMap[scheduledEventId];
        delete system._snapshot._scheduledEvents[scheduledEventId];
        system._relay(source, target, event);
      }, delay);
      timerMap[scheduledEventId] = timeout;
    },
    cancel: (source, id) => {
      const scheduledEventId = createScheduledEventId(source, id);
      const timeout = timerMap[scheduledEventId];
      delete timerMap[scheduledEventId];
      delete system._snapshot._scheduledEvents[scheduledEventId];
      clock.clearTimeout(timeout);
    },
    cancelAll: (actorRef) => {
      for (const scheduledEventId in system._snapshot._scheduledEvents) {
        const scheduledEvent = system._snapshot._scheduledEvents[scheduledEventId];
        if (scheduledEvent.source === actorRef) {
          scheduler.cancel(actorRef, scheduledEvent.id);
        }
      }
    }
  };
  const sendInspectionEvent = (event) => {
    if (!inspectionObservers.size) {
      return;
    }
    const resolvedInspectionEvent = {
      ...event,
      rootId: rootActor.sessionId
    };
    inspectionObservers.forEach((observer) => observer.next?.(resolvedInspectionEvent));
  };
  const system = {
    _snapshot: {
      _scheduledEvents: (options?.snapshot && options.snapshot.scheduler) ?? {}
    },
    _bookId: () => `x:${idCounter++}`,
    _register: (sessionId, actorRef) => {
      children.set(sessionId, actorRef);
      return sessionId;
    },
    _unregister: (actorRef) => {
      children.delete(actorRef.sessionId);
      const systemId = reverseKeyedActors.get(actorRef);
      if (systemId !== void 0) {
        keyedActors.delete(systemId);
        reverseKeyedActors.delete(actorRef);
      }
    },
    get: (systemId) => {
      return keyedActors.get(systemId);
    },
    _set: (systemId, actorRef) => {
      const existing = keyedActors.get(systemId);
      if (existing && existing !== actorRef) {
        throw new Error(`Actor with system ID '${systemId}' already exists.`);
      }
      keyedActors.set(systemId, actorRef);
      reverseKeyedActors.set(actorRef, systemId);
    },
    inspect: (observer) => {
      inspectionObservers.add(observer);
    },
    _sendInspectionEvent: sendInspectionEvent,
    _relay: (source, target, event) => {
      system._sendInspectionEvent({
        type: "@xstate.event",
        sourceRef: source,
        actorRef: target,
        event
      });
      target._send(event);
    },
    scheduler,
    getSnapshot: () => {
      return {
        _scheduledEvents: {
          ...system._snapshot._scheduledEvents
        }
      };
    },
    start: () => {
      const scheduledEvents = system._snapshot._scheduledEvents;
      system._snapshot._scheduledEvents = {};
      for (const scheduledId in scheduledEvents) {
        const {
          source,
          target,
          event,
          delay,
          id
        } = scheduledEvents[scheduledId];
        scheduler.schedule(source, target, event, delay, id);
      }
    },
    _clock: clock,
    _logger: logger
  };
  return system;
}
function matchesState(parentStateId, childStateId) {
  const parentStateValue = toStateValue(parentStateId);
  const childStateValue = toStateValue(childStateId);
  if (typeof childStateValue === "string") {
    if (typeof parentStateValue === "string") {
      return childStateValue === parentStateValue;
    }
    return false;
  }
  if (typeof parentStateValue === "string") {
    return parentStateValue in childStateValue;
  }
  return Object.keys(parentStateValue).every((key) => {
    if (!(key in childStateValue)) {
      return false;
    }
    return matchesState(parentStateValue[key], childStateValue[key]);
  });
}
function toStatePath(stateId) {
  if (isArray(stateId)) {
    return stateId;
  }
  let result = [];
  let segment = "";
  for (let i = 0; i < stateId.length; i++) {
    const char = stateId.charCodeAt(i);
    switch (char) {
      // \
      case 92:
        segment += stateId[i + 1];
        i++;
        continue;
      // .
      case 46:
        result.push(segment);
        segment = "";
        continue;
    }
    segment += stateId[i];
  }
  result.push(segment);
  return result;
}
function toStateValue(stateValue) {
  if (isMachineSnapshot(stateValue)) {
    return stateValue.value;
  }
  if (typeof stateValue !== "string") {
    return stateValue;
  }
  const statePath = toStatePath(stateValue);
  return pathToStateValue(statePath);
}
function pathToStateValue(statePath) {
  if (statePath.length === 1) {
    return statePath[0];
  }
  const value = {};
  let marker = value;
  for (let i = 0; i < statePath.length - 1; i++) {
    if (i === statePath.length - 2) {
      marker[statePath[i]] = statePath[i + 1];
    } else {
      const previous = marker;
      marker = {};
      previous[statePath[i]] = marker;
    }
  }
  return value;
}
function mapValues(collection, iteratee) {
  const result = {};
  const collectionKeys = Object.keys(collection);
  for (let i = 0; i < collectionKeys.length; i++) {
    const key = collectionKeys[i];
    result[key] = iteratee(collection[key], key, collection, i);
  }
  return result;
}
function toArrayStrict(value) {
  if (isArray(value)) {
    return value;
  }
  return [value];
}
function toArray(value) {
  if (value === void 0) {
    return [];
  }
  return toArrayStrict(value);
}
function resolveOutput(mapper, context, event, self2) {
  if (typeof mapper === "function") {
    return mapper({
      context,
      event,
      self: self2
    });
  }
  return mapper;
}
function isArray(value) {
  return Array.isArray(value);
}
function isErrorActorEvent(event) {
  return event.type.startsWith("xstate.error.actor");
}
function toTransitionConfigArray(configLike) {
  return toArrayStrict(configLike).map((transitionLike) => {
    if (typeof transitionLike === "undefined" || typeof transitionLike === "string") {
      return {
        target: transitionLike
      };
    }
    return transitionLike;
  });
}
function normalizeTarget(target) {
  if (target === void 0 || target === TARGETLESS_KEY) {
    return void 0;
  }
  return toArray(target);
}
function toObserver(nextHandler, errorHandler, completionHandler) {
  const isObserver = typeof nextHandler === "object";
  const self2 = isObserver ? nextHandler : void 0;
  return {
    next: (isObserver ? nextHandler.next : nextHandler)?.bind(self2),
    error: (isObserver ? nextHandler.error : errorHandler)?.bind(self2),
    complete: (isObserver ? nextHandler.complete : completionHandler)?.bind(self2)
  };
}
function createInvokeId(stateNodeId, index) {
  return `${index}.${stateNodeId}`;
}
function resolveReferencedActor(machine, src) {
  const match = src.match(/^xstate\.invoke\.(\d+)\.(.*)/);
  if (!match) {
    return machine.implementations.actors[src];
  }
  const [, indexStr, nodeId] = match;
  const node = machine.getStateNodeById(nodeId);
  const invokeConfig = node.config.invoke;
  return (Array.isArray(invokeConfig) ? invokeConfig[indexStr] : invokeConfig).src;
}
var $$ACTOR_TYPE = 1;
var ProcessingStatus = /* @__PURE__ */ function(ProcessingStatus2) {
  ProcessingStatus2[ProcessingStatus2["NotStarted"] = 0] = "NotStarted";
  ProcessingStatus2[ProcessingStatus2["Running"] = 1] = "Running";
  ProcessingStatus2[ProcessingStatus2["Stopped"] = 2] = "Stopped";
  return ProcessingStatus2;
}({});
var defaultOptions = {
  clock: {
    setTimeout: (fn, ms) => {
      return setTimeout(fn, ms);
    },
    clearTimeout: (id) => {
      return clearTimeout(id);
    }
  },
  logger: console.log.bind(console),
  devTools: false
};
var Actor = class {
  /**
   * Creates a new actor instance for the given logic with the provided options, if any.
   *
   * @param logic The logic to create an actor from
   * @param options Actor options
   */
  constructor(logic, options) {
    this.logic = logic;
    this._snapshot = void 0;
    this.clock = void 0;
    this.options = void 0;
    this.id = void 0;
    this.mailbox = new Mailbox(this._process.bind(this));
    this.observers = /* @__PURE__ */ new Set();
    this.eventListeners = /* @__PURE__ */ new Map();
    this.logger = void 0;
    this._processingStatus = ProcessingStatus.NotStarted;
    this._parent = void 0;
    this._syncSnapshot = void 0;
    this.ref = void 0;
    this._actorScope = void 0;
    this._systemId = void 0;
    this.sessionId = void 0;
    this.system = void 0;
    this._doneEvent = void 0;
    this.src = void 0;
    this._deferred = [];
    const resolvedOptions = {
      ...defaultOptions,
      ...options
    };
    const {
      clock,
      logger,
      parent,
      syncSnapshot,
      id,
      systemId,
      inspect
    } = resolvedOptions;
    this.system = parent ? parent.system : createSystem(this, {
      clock,
      logger
    });
    if (inspect && !parent) {
      this.system.inspect(toObserver(inspect));
    }
    this.sessionId = this.system._bookId();
    this.id = id ?? this.sessionId;
    this.logger = options?.logger ?? this.system._logger;
    this.clock = options?.clock ?? this.system._clock;
    this._parent = parent;
    this._syncSnapshot = syncSnapshot;
    this.options = resolvedOptions;
    this.src = resolvedOptions.src ?? logic;
    this.ref = this;
    this._actorScope = {
      self: this,
      id: this.id,
      sessionId: this.sessionId,
      logger: this.logger,
      defer: (fn) => {
        this._deferred.push(fn);
      },
      system: this.system,
      stopChild: (child) => {
        if (child._parent !== this) {
          throw new Error(`Cannot stop child actor ${child.id} of ${this.id} because it is not a child`);
        }
        child._stop();
      },
      emit: (emittedEvent) => {
        const listeners = this.eventListeners.get(emittedEvent.type);
        if (!listeners) {
          return;
        }
        for (const handler of Array.from(listeners)) {
          handler(emittedEvent);
        }
      }
    };
    this.send = this.send.bind(this);
    this.system._sendInspectionEvent({
      type: "@xstate.actor",
      actorRef: this
    });
    if (systemId) {
      this._systemId = systemId;
      this.system._set(systemId, this);
    }
    this._initState(options?.snapshot ?? options?.state);
    if (systemId && this._snapshot.status !== "active") {
      this.system._unregister(this);
    }
  }
  _initState(persistedState) {
    try {
      this._snapshot = persistedState ? this.logic.restoreSnapshot ? this.logic.restoreSnapshot(persistedState, this._actorScope) : persistedState : this.logic.getInitialSnapshot(this._actorScope, this.options?.input);
    } catch (err) {
      this._snapshot = {
        status: "error",
        output: void 0,
        error: err
      };
    }
  }
  update(snapshot, event) {
    this._snapshot = snapshot;
    let deferredFn;
    while (deferredFn = this._deferred.shift()) {
      try {
        deferredFn();
      } catch (err) {
        this._deferred.length = 0;
        this._snapshot = {
          ...snapshot,
          status: "error",
          error: err
        };
      }
    }
    switch (this._snapshot.status) {
      case "active":
        for (const observer of this.observers) {
          try {
            observer.next?.(snapshot);
          } catch (err) {
            reportUnhandledError(err);
          }
        }
        break;
      case "done":
        for (const observer of this.observers) {
          try {
            observer.next?.(snapshot);
          } catch (err) {
            reportUnhandledError(err);
          }
        }
        this._stopProcedure();
        this._complete();
        this._doneEvent = createDoneActorEvent(this.id, this._snapshot.output);
        if (this._parent) {
          this.system._relay(this, this._parent, this._doneEvent);
        }
        break;
      case "error":
        this._error(this._snapshot.error);
        break;
    }
    this.system._sendInspectionEvent({
      type: "@xstate.snapshot",
      actorRef: this,
      event,
      snapshot
    });
  }
  /**
   * Subscribe an observer to an actor’s snapshot values.
   *
   * @remarks
   * The observer will receive the actor’s snapshot value when it is emitted. The observer can be:
   * - A plain function that receives the latest snapshot, or
   * - An observer object whose `.next(snapshot)` method receives the latest snapshot
   *
   * @example
   * ```ts
   * // Observer as a plain function
   * const subscription = actor.subscribe((snapshot) => {
   *   console.log(snapshot);
   * });
   * ```
   *
   * @example
   * ```ts
   * // Observer as an object
   * const subscription = actor.subscribe({
   *   next(snapshot) {
   *     console.log(snapshot);
   *   },
   *   error(err) {
   *     // ...
   *   },
   *   complete() {
   *     // ...
   *   },
   * });
   * ```
   *
   * The return value of `actor.subscribe(observer)` is a subscription object that has an `.unsubscribe()` method. You can call `subscription.unsubscribe()` to unsubscribe the observer:
   *
   * @example
   * ```ts
   * const subscription = actor.subscribe((snapshot) => {
   *   // ...
   * });
   *
   * // Unsubscribe the observer
   * subscription.unsubscribe();
   * ```
   *
   * When the actor is stopped, all of its observers will automatically be unsubscribed.
   *
   * @param observer - Either a plain function that receives the latest snapshot, or an observer object whose `.next(snapshot)` method receives the latest snapshot
   */
  subscribe(nextListenerOrObserver, errorListener, completeListener) {
    const observer = toObserver(nextListenerOrObserver, errorListener, completeListener);
    if (this._processingStatus !== ProcessingStatus.Stopped) {
      this.observers.add(observer);
    } else {
      switch (this._snapshot.status) {
        case "done":
          try {
            observer.complete?.();
          } catch (err) {
            reportUnhandledError(err);
          }
          break;
        case "error": {
          const err = this._snapshot.error;
          if (!observer.error) {
            reportUnhandledError(err);
          } else {
            try {
              observer.error(err);
            } catch (err2) {
              reportUnhandledError(err2);
            }
          }
          break;
        }
      }
    }
    return {
      unsubscribe: () => {
        this.observers.delete(observer);
      }
    };
  }
  on(type, handler) {
    let listeners = this.eventListeners.get(type);
    if (!listeners) {
      listeners = /* @__PURE__ */ new Set();
      this.eventListeners.set(type, listeners);
    }
    const wrappedHandler = handler.bind(void 0);
    listeners.add(wrappedHandler);
    return {
      unsubscribe: () => {
        listeners.delete(wrappedHandler);
      }
    };
  }
  /**
   * Starts the Actor from the initial state
   */
  start() {
    if (this._processingStatus === ProcessingStatus.Running) {
      return this;
    }
    if (this._syncSnapshot) {
      this.subscribe({
        next: (snapshot) => {
          if (snapshot.status === "active") {
            this.system._relay(this, this._parent, {
              type: `xstate.snapshot.${this.id}`,
              snapshot
            });
          }
        },
        error: () => {
        }
      });
    }
    this.system._register(this.sessionId, this);
    if (this._systemId) {
      this.system._set(this._systemId, this);
    }
    this._processingStatus = ProcessingStatus.Running;
    const initEvent = createInitEvent(this.options.input);
    this.system._sendInspectionEvent({
      type: "@xstate.event",
      sourceRef: this._parent,
      actorRef: this,
      event: initEvent
    });
    const status = this._snapshot.status;
    switch (status) {
      case "done":
        this.update(this._snapshot, initEvent);
        return this;
      case "error":
        this._error(this._snapshot.error);
        return this;
    }
    if (!this._parent) {
      this.system.start();
    }
    if (this.logic.start) {
      try {
        this.logic.start(this._snapshot, this._actorScope);
      } catch (err) {
        this._snapshot = {
          ...this._snapshot,
          status: "error",
          error: err
        };
        this._error(err);
        return this;
      }
    }
    this.update(this._snapshot, initEvent);
    if (this.options.devTools) {
      this.attachDevTools();
    }
    this.mailbox.start();
    return this;
  }
  _process(event) {
    let nextState;
    let caughtError;
    try {
      nextState = this.logic.transition(this._snapshot, event, this._actorScope);
    } catch (err) {
      caughtError = {
        err
      };
    }
    if (caughtError) {
      const {
        err
      } = caughtError;
      this._snapshot = {
        ...this._snapshot,
        status: "error",
        error: err
      };
      this._error(err);
      return;
    }
    this.update(nextState, event);
    if (event.type === XSTATE_STOP) {
      this._stopProcedure();
      this._complete();
    }
  }
  _stop() {
    if (this._processingStatus === ProcessingStatus.Stopped) {
      return this;
    }
    this.mailbox.clear();
    if (this._processingStatus === ProcessingStatus.NotStarted) {
      this._processingStatus = ProcessingStatus.Stopped;
      return this;
    }
    this.mailbox.enqueue({
      type: XSTATE_STOP
    });
    return this;
  }
  /**
   * Stops the Actor and unsubscribe all listeners.
   */
  stop() {
    if (this._parent) {
      throw new Error("A non-root actor cannot be stopped directly.");
    }
    return this._stop();
  }
  _complete() {
    for (const observer of this.observers) {
      try {
        observer.complete?.();
      } catch (err) {
        reportUnhandledError(err);
      }
    }
    this.observers.clear();
  }
  _reportError(err) {
    if (!this.observers.size) {
      if (!this._parent) {
        reportUnhandledError(err);
      }
      return;
    }
    let reportError = false;
    for (const observer of this.observers) {
      const errorListener = observer.error;
      reportError ||= !errorListener;
      try {
        errorListener?.(err);
      } catch (err2) {
        reportUnhandledError(err2);
      }
    }
    this.observers.clear();
    if (reportError) {
      reportUnhandledError(err);
    }
  }
  _error(err) {
    this._stopProcedure();
    this._reportError(err);
    if (this._parent) {
      this.system._relay(this, this._parent, createErrorActorEvent(this.id, err));
    }
  }
  // TODO: atm children don't belong entirely to the actor so
  // in a way - it's not even super aware of them
  // so we can't stop them from here but we really should!
  // right now, they are being stopped within the machine's transition
  // but that could throw and leave us with "orphaned" active actors
  _stopProcedure() {
    if (this._processingStatus !== ProcessingStatus.Running) {
      return this;
    }
    this.system.scheduler.cancelAll(this);
    this.mailbox.clear();
    this.mailbox = new Mailbox(this._process.bind(this));
    this._processingStatus = ProcessingStatus.Stopped;
    this.system._unregister(this);
    return this;
  }
  /**
   * @internal
   */
  _send(event) {
    if (this._processingStatus === ProcessingStatus.Stopped) {
      return;
    }
    this.mailbox.enqueue(event);
  }
  /**
   * Sends an event to the running Actor to trigger a transition.
   *
   * @param event The event to send
   */
  send(event) {
    this.system._relay(void 0, this, event);
  }
  attachDevTools() {
    const {
      devTools
    } = this.options;
    if (devTools) {
      const resolvedDevToolsAdapter = typeof devTools === "function" ? devTools : devToolsAdapter;
      resolvedDevToolsAdapter(this);
    }
  }
  toJSON() {
    return {
      xstate$$type: $$ACTOR_TYPE,
      id: this.id
    };
  }
  /**
   * Obtain the internal state of the actor, which can be persisted.
   *
   * @remarks
   * The internal state can be persisted from any actor, not only machines.
   *
   * Note that the persisted state is not the same as the snapshot from {@link Actor.getSnapshot}. Persisted state represents the internal state of the actor, while snapshots represent the actor's last emitted value.
   *
   * Can be restored with {@link ActorOptions.state}
   *
   * @see https://stately.ai/docs/persistence
   */
  getPersistedSnapshot(options) {
    return this.logic.getPersistedSnapshot(this._snapshot, options);
  }
  [symbolObservable]() {
    return this;
  }
  /**
   * Read an actor’s snapshot synchronously.
   *
   * @remarks
   * The snapshot represent an actor's last emitted value.
   *
   * When an actor receives an event, its internal state may change.
   * An actor may emit a snapshot when a state transition occurs.
   *
   * Note that some actors, such as callback actors generated with `fromCallback`, will not emit snapshots.
   *
   * @see {@link Actor.subscribe} to subscribe to an actor’s snapshot values.
   * @see {@link Actor.getPersistedSnapshot} to persist the internal state of an actor (which is more than just a snapshot).
   */
  getSnapshot() {
    return this._snapshot;
  }
};
function createActor(logic, ...[options]) {
  return new Actor(logic, options);
}
function resolveCancel(_, snapshot, actionArgs, actionParams, {
  sendId
}) {
  const resolvedSendId = typeof sendId === "function" ? sendId(actionArgs, actionParams) : sendId;
  return [snapshot, resolvedSendId];
}
function executeCancel(actorScope, resolvedSendId) {
  actorScope.defer(() => {
    actorScope.system.scheduler.cancel(actorScope.self, resolvedSendId);
  });
}
function cancel(sendId) {
  function cancel2(args, params) {
  }
  cancel2.type = "xstate.cancel";
  cancel2.sendId = sendId;
  cancel2.resolve = resolveCancel;
  cancel2.execute = executeCancel;
  return cancel2;
}
function resolveSpawn(actorScope, snapshot, actionArgs, _actionParams, {
  id,
  systemId,
  src,
  input,
  syncSnapshot
}) {
  const logic = typeof src === "string" ? resolveReferencedActor(snapshot.machine, src) : src;
  const resolvedId = typeof id === "function" ? id(actionArgs) : id;
  let actorRef;
  if (logic) {
    actorRef = createActor(logic, {
      id: resolvedId,
      src,
      parent: actorScope.self,
      syncSnapshot,
      systemId,
      input: typeof input === "function" ? input({
        context: snapshot.context,
        event: actionArgs.event,
        self: actorScope.self
      }) : input
    });
  }
  return [cloneMachineSnapshot(snapshot, {
    children: {
      ...snapshot.children,
      [resolvedId]: actorRef
    }
  }), {
    id,
    actorRef
  }];
}
function executeSpawn(actorScope, {
  id,
  actorRef
}) {
  if (!actorRef) {
    return;
  }
  actorScope.defer(() => {
    if (actorRef._processingStatus === ProcessingStatus.Stopped) {
      return;
    }
    actorRef.start();
  });
}
function spawnChild(...[src, {
  id,
  systemId,
  input,
  syncSnapshot = false
} = {}]) {
  function spawnChild2(args, params) {
  }
  spawnChild2.type = "snapshot.spawnChild";
  spawnChild2.id = id;
  spawnChild2.systemId = systemId;
  spawnChild2.src = src;
  spawnChild2.input = input;
  spawnChild2.syncSnapshot = syncSnapshot;
  spawnChild2.resolve = resolveSpawn;
  spawnChild2.execute = executeSpawn;
  return spawnChild2;
}
function resolveStop(_, snapshot, args, actionParams, {
  actorRef
}) {
  const actorRefOrString = typeof actorRef === "function" ? actorRef(args, actionParams) : actorRef;
  const resolvedActorRef = typeof actorRefOrString === "string" ? snapshot.children[actorRefOrString] : actorRefOrString;
  let children = snapshot.children;
  if (resolvedActorRef) {
    children = {
      ...children
    };
    delete children[resolvedActorRef.id];
  }
  return [cloneMachineSnapshot(snapshot, {
    children
  }), resolvedActorRef];
}
function executeStop(actorScope, actorRef) {
  if (!actorRef) {
    return;
  }
  actorScope.system._unregister(actorRef);
  if (actorRef._processingStatus !== ProcessingStatus.Running) {
    actorScope.stopChild(actorRef);
    return;
  }
  actorScope.defer(() => {
    actorScope.stopChild(actorRef);
  });
}
function stopChild(actorRef) {
  function stop2(args, params) {
  }
  stop2.type = "xstate.stopChild";
  stop2.actorRef = actorRef;
  stop2.resolve = resolveStop;
  stop2.execute = executeStop;
  return stop2;
}
function evaluateGuard(guard, context, event, snapshot) {
  const {
    machine
  } = snapshot;
  const isInline = typeof guard === "function";
  const resolved = isInline ? guard : machine.implementations.guards[typeof guard === "string" ? guard : guard.type];
  if (!isInline && !resolved) {
    throw new Error(`Guard '${typeof guard === "string" ? guard : guard.type}' is not implemented.'.`);
  }
  if (typeof resolved !== "function") {
    return evaluateGuard(resolved, context, event, snapshot);
  }
  const guardArgs = {
    context,
    event
  };
  const guardParams = isInline || typeof guard === "string" ? void 0 : "params" in guard ? typeof guard.params === "function" ? guard.params({
    context,
    event
  }) : guard.params : void 0;
  if (!("check" in resolved)) {
    return resolved(guardArgs, guardParams);
  }
  const builtinGuard = resolved;
  return builtinGuard.check(
    snapshot,
    guardArgs,
    resolved
    // this holds all params
  );
}
var isAtomicStateNode = (stateNode) => stateNode.type === "atomic" || stateNode.type === "final";
function getChildren(stateNode) {
  return Object.values(stateNode.states).filter((sn) => sn.type !== "history");
}
function getProperAncestors(stateNode, toStateNode) {
  const ancestors = [];
  if (toStateNode === stateNode) {
    return ancestors;
  }
  let m = stateNode.parent;
  while (m && m !== toStateNode) {
    ancestors.push(m);
    m = m.parent;
  }
  return ancestors;
}
function getAllStateNodes(stateNodes) {
  const nodeSet = new Set(stateNodes);
  const adjList = getAdjList(nodeSet);
  for (const s of nodeSet) {
    if (s.type === "compound" && (!adjList.get(s) || !adjList.get(s).length)) {
      getInitialStateNodesWithTheirAncestors(s).forEach((sn) => nodeSet.add(sn));
    } else {
      if (s.type === "parallel") {
        for (const child of getChildren(s)) {
          if (child.type === "history") {
            continue;
          }
          if (!nodeSet.has(child)) {
            const initialStates = getInitialStateNodesWithTheirAncestors(child);
            for (const initialStateNode of initialStates) {
              nodeSet.add(initialStateNode);
            }
          }
        }
      }
    }
  }
  for (const s of nodeSet) {
    let m = s.parent;
    while (m) {
      nodeSet.add(m);
      m = m.parent;
    }
  }
  return nodeSet;
}
function getValueFromAdj(baseNode, adjList) {
  const childStateNodes = adjList.get(baseNode);
  if (!childStateNodes) {
    return {};
  }
  if (baseNode.type === "compound") {
    const childStateNode = childStateNodes[0];
    if (childStateNode) {
      if (isAtomicStateNode(childStateNode)) {
        return childStateNode.key;
      }
    } else {
      return {};
    }
  }
  const stateValue = {};
  for (const childStateNode of childStateNodes) {
    stateValue[childStateNode.key] = getValueFromAdj(childStateNode, adjList);
  }
  return stateValue;
}
function getAdjList(stateNodes) {
  const adjList = /* @__PURE__ */ new Map();
  for (const s of stateNodes) {
    if (!adjList.has(s)) {
      adjList.set(s, []);
    }
    if (s.parent) {
      if (!adjList.has(s.parent)) {
        adjList.set(s.parent, []);
      }
      adjList.get(s.parent).push(s);
    }
  }
  return adjList;
}
function getStateValue(rootNode, stateNodes) {
  const config = getAllStateNodes(stateNodes);
  return getValueFromAdj(rootNode, getAdjList(config));
}
function isInFinalState(stateNodeSet, stateNode) {
  if (stateNode.type === "compound") {
    return getChildren(stateNode).some((s) => s.type === "final" && stateNodeSet.has(s));
  }
  if (stateNode.type === "parallel") {
    return getChildren(stateNode).every((sn) => isInFinalState(stateNodeSet, sn));
  }
  return stateNode.type === "final";
}
var isStateId = (str) => str[0] === STATE_IDENTIFIER;
function getCandidates(stateNode, receivedEventType) {
  const candidates = stateNode.transitions.get(receivedEventType) || [...stateNode.transitions.keys()].filter((eventDescriptor) => {
    if (eventDescriptor === WILDCARD) {
      return true;
    }
    if (!eventDescriptor.endsWith(".*")) {
      return false;
    }
    const partialEventTokens = eventDescriptor.split(".");
    const eventTokens = receivedEventType.split(".");
    for (let tokenIndex = 0; tokenIndex < partialEventTokens.length; tokenIndex++) {
      const partialEventToken = partialEventTokens[tokenIndex];
      const eventToken = eventTokens[tokenIndex];
      if (partialEventToken === "*") {
        const isLastToken = tokenIndex === partialEventTokens.length - 1;
        return isLastToken;
      }
      if (partialEventToken !== eventToken) {
        return false;
      }
    }
    return true;
  }).sort((a, b) => b.length - a.length).flatMap((key) => stateNode.transitions.get(key));
  return candidates;
}
function getDelayedTransitions(stateNode) {
  const afterConfig = stateNode.config.after;
  if (!afterConfig) {
    return [];
  }
  const mutateEntryExit = (delay, i) => {
    const afterEvent = createAfterEvent(delay, stateNode.id);
    const eventType = afterEvent.type;
    stateNode.entry.push(raise(afterEvent, {
      id: eventType,
      delay
    }));
    stateNode.exit.push(cancel(eventType));
    return eventType;
  };
  const delayedTransitions = Object.keys(afterConfig).flatMap((delay, i) => {
    const configTransition = afterConfig[delay];
    const resolvedTransition = typeof configTransition === "string" ? {
      target: configTransition
    } : configTransition;
    const resolvedDelay = Number.isNaN(+delay) ? delay : +delay;
    const eventType = mutateEntryExit(resolvedDelay);
    return toArray(resolvedTransition).map((transition) => ({
      ...transition,
      event: eventType,
      delay: resolvedDelay
    }));
  });
  return delayedTransitions.map((delayedTransition) => {
    const {
      delay
    } = delayedTransition;
    return {
      ...formatTransition(stateNode, delayedTransition.event, delayedTransition),
      delay
    };
  });
}
function formatTransition(stateNode, descriptor, transitionConfig) {
  const normalizedTarget = normalizeTarget(transitionConfig.target);
  const reenter = transitionConfig.reenter ?? false;
  const target = resolveTarget(stateNode, normalizedTarget);
  const transition = {
    ...transitionConfig,
    actions: toArray(transitionConfig.actions),
    guard: transitionConfig.guard,
    target,
    source: stateNode,
    reenter,
    eventType: descriptor,
    toJSON: () => ({
      ...transition,
      source: `#${stateNode.id}`,
      target: target ? target.map((t) => `#${t.id}`) : void 0
    })
  };
  return transition;
}
function formatTransitions(stateNode) {
  const transitions = /* @__PURE__ */ new Map();
  if (stateNode.config.on) {
    for (const descriptor of Object.keys(stateNode.config.on)) {
      if (descriptor === NULL_EVENT) {
        throw new Error('Null events ("") cannot be specified as a transition key. Use `always: { ... }` instead.');
      }
      const transitionsConfig = stateNode.config.on[descriptor];
      transitions.set(descriptor, toTransitionConfigArray(transitionsConfig).map((t) => formatTransition(stateNode, descriptor, t)));
    }
  }
  if (stateNode.config.onDone) {
    const descriptor = `xstate.done.state.${stateNode.id}`;
    transitions.set(descriptor, toTransitionConfigArray(stateNode.config.onDone).map((t) => formatTransition(stateNode, descriptor, t)));
  }
  for (const invokeDef of stateNode.invoke) {
    if (invokeDef.onDone) {
      const descriptor = `xstate.done.actor.${invokeDef.id}`;
      transitions.set(descriptor, toTransitionConfigArray(invokeDef.onDone).map((t) => formatTransition(stateNode, descriptor, t)));
    }
    if (invokeDef.onError) {
      const descriptor = `xstate.error.actor.${invokeDef.id}`;
      transitions.set(descriptor, toTransitionConfigArray(invokeDef.onError).map((t) => formatTransition(stateNode, descriptor, t)));
    }
    if (invokeDef.onSnapshot) {
      const descriptor = `xstate.snapshot.${invokeDef.id}`;
      transitions.set(descriptor, toTransitionConfigArray(invokeDef.onSnapshot).map((t) => formatTransition(stateNode, descriptor, t)));
    }
  }
  for (const delayedTransition of stateNode.after) {
    let existing = transitions.get(delayedTransition.eventType);
    if (!existing) {
      existing = [];
      transitions.set(delayedTransition.eventType, existing);
    }
    existing.push(delayedTransition);
  }
  return transitions;
}
function formatInitialTransition(stateNode, _target) {
  const resolvedTarget = typeof _target === "string" ? stateNode.states[_target] : _target ? stateNode.states[_target.target] : void 0;
  if (!resolvedTarget && _target) {
    throw new Error(`Initial state node "${_target}" not found on parent state node #${stateNode.id}`);
  }
  const transition = {
    source: stateNode,
    actions: !_target || typeof _target === "string" ? [] : toArray(_target.actions),
    eventType: null,
    reenter: false,
    target: resolvedTarget ? [resolvedTarget] : [],
    toJSON: () => ({
      ...transition,
      source: `#${stateNode.id}`,
      target: resolvedTarget ? [`#${resolvedTarget.id}`] : []
    })
  };
  return transition;
}
function resolveTarget(stateNode, targets) {
  if (targets === void 0) {
    return void 0;
  }
  return targets.map((target) => {
    if (typeof target !== "string") {
      return target;
    }
    if (isStateId(target)) {
      return stateNode.machine.getStateNodeById(target);
    }
    const isInternalTarget = target[0] === STATE_DELIMITER;
    if (isInternalTarget && !stateNode.parent) {
      return getStateNodeByPath(stateNode, target.slice(1));
    }
    const resolvedTarget = isInternalTarget ? stateNode.key + target : target;
    if (stateNode.parent) {
      try {
        const targetStateNode = getStateNodeByPath(stateNode.parent, resolvedTarget);
        return targetStateNode;
      } catch (err) {
        throw new Error(`Invalid transition definition for state node '${stateNode.id}':
${err.message}`);
      }
    } else {
      throw new Error(`Invalid target: "${target}" is not a valid target from the root node. Did you mean ".${target}"?`);
    }
  });
}
function resolveHistoryDefaultTransition(stateNode) {
  const normalizedTarget = normalizeTarget(stateNode.config.target);
  if (!normalizedTarget) {
    return stateNode.parent.initial;
  }
  return {
    target: normalizedTarget.map((t) => typeof t === "string" ? getStateNodeByPath(stateNode.parent, t) : t)
  };
}
function isHistoryNode(stateNode) {
  return stateNode.type === "history";
}
function getInitialStateNodesWithTheirAncestors(stateNode) {
  const states = getInitialStateNodes(stateNode);
  for (const initialState of states) {
    for (const ancestor of getProperAncestors(initialState, stateNode)) {
      states.add(ancestor);
    }
  }
  return states;
}
function getInitialStateNodes(stateNode) {
  const set = /* @__PURE__ */ new Set();
  function iter(descStateNode) {
    if (set.has(descStateNode)) {
      return;
    }
    set.add(descStateNode);
    if (descStateNode.type === "compound") {
      iter(descStateNode.initial.target[0]);
    } else if (descStateNode.type === "parallel") {
      for (const child of getChildren(descStateNode)) {
        iter(child);
      }
    }
  }
  iter(stateNode);
  return set;
}
function getStateNode(stateNode, stateKey) {
  if (isStateId(stateKey)) {
    return stateNode.machine.getStateNodeById(stateKey);
  }
  if (!stateNode.states) {
    throw new Error(`Unable to retrieve child state '${stateKey}' from '${stateNode.id}'; no child states exist.`);
  }
  const result = stateNode.states[stateKey];
  if (!result) {
    throw new Error(`Child state '${stateKey}' does not exist on '${stateNode.id}'`);
  }
  return result;
}
function getStateNodeByPath(stateNode, statePath) {
  if (typeof statePath === "string" && isStateId(statePath)) {
    try {
      return stateNode.machine.getStateNodeById(statePath);
    } catch (e) {
    }
  }
  const arrayStatePath = toStatePath(statePath).slice();
  let currentStateNode = stateNode;
  while (arrayStatePath.length) {
    const key = arrayStatePath.shift();
    if (!key.length) {
      break;
    }
    currentStateNode = getStateNode(currentStateNode, key);
  }
  return currentStateNode;
}
function getStateNodes(stateNode, stateValue) {
  if (typeof stateValue === "string") {
    const childStateNode = stateNode.states[stateValue];
    if (!childStateNode) {
      throw new Error(`State '${stateValue}' does not exist on '${stateNode.id}'`);
    }
    return [stateNode, childStateNode];
  }
  const childStateKeys = Object.keys(stateValue);
  const childStateNodes = childStateKeys.map((subStateKey) => getStateNode(stateNode, subStateKey)).filter(Boolean);
  return [stateNode.machine.root, stateNode].concat(childStateNodes, childStateKeys.reduce((allSubStateNodes, subStateKey) => {
    const subStateNode = getStateNode(stateNode, subStateKey);
    if (!subStateNode) {
      return allSubStateNodes;
    }
    const subStateNodes = getStateNodes(subStateNode, stateValue[subStateKey]);
    return allSubStateNodes.concat(subStateNodes);
  }, []));
}
function transitionAtomicNode(stateNode, stateValue, snapshot, event) {
  const childStateNode = getStateNode(stateNode, stateValue);
  const next = childStateNode.next(snapshot, event);
  if (!next || !next.length) {
    return stateNode.next(snapshot, event);
  }
  return next;
}
function transitionCompoundNode(stateNode, stateValue, snapshot, event) {
  const subStateKeys = Object.keys(stateValue);
  const childStateNode = getStateNode(stateNode, subStateKeys[0]);
  const next = transitionNode(childStateNode, stateValue[subStateKeys[0]], snapshot, event);
  if (!next || !next.length) {
    return stateNode.next(snapshot, event);
  }
  return next;
}
function transitionParallelNode(stateNode, stateValue, snapshot, event) {
  const allInnerTransitions = [];
  for (const subStateKey of Object.keys(stateValue)) {
    const subStateValue = stateValue[subStateKey];
    if (!subStateValue) {
      continue;
    }
    const subStateNode = getStateNode(stateNode, subStateKey);
    const innerTransitions = transitionNode(subStateNode, subStateValue, snapshot, event);
    if (innerTransitions) {
      allInnerTransitions.push(...innerTransitions);
    }
  }
  if (!allInnerTransitions.length) {
    return stateNode.next(snapshot, event);
  }
  return allInnerTransitions;
}
function transitionNode(stateNode, stateValue, snapshot, event) {
  if (typeof stateValue === "string") {
    return transitionAtomicNode(stateNode, stateValue, snapshot, event);
  }
  if (Object.keys(stateValue).length === 1) {
    return transitionCompoundNode(stateNode, stateValue, snapshot, event);
  }
  return transitionParallelNode(stateNode, stateValue, snapshot, event);
}
function getHistoryNodes(stateNode) {
  return Object.keys(stateNode.states).map((key) => stateNode.states[key]).filter((sn) => sn.type === "history");
}
function isDescendant(childStateNode, parentStateNode) {
  let marker = childStateNode;
  while (marker.parent && marker.parent !== parentStateNode) {
    marker = marker.parent;
  }
  return marker.parent === parentStateNode;
}
function hasIntersection(s1, s2) {
  const set1 = new Set(s1);
  const set2 = new Set(s2);
  for (const item of set1) {
    if (set2.has(item)) {
      return true;
    }
  }
  for (const item of set2) {
    if (set1.has(item)) {
      return true;
    }
  }
  return false;
}
function removeConflictingTransitions(enabledTransitions, stateNodeSet, historyValue) {
  const filteredTransitions = /* @__PURE__ */ new Set();
  for (const t1 of enabledTransitions) {
    let t1Preempted = false;
    const transitionsToRemove = /* @__PURE__ */ new Set();
    for (const t2 of filteredTransitions) {
      if (hasIntersection(computeExitSet([t1], stateNodeSet, historyValue), computeExitSet([t2], stateNodeSet, historyValue))) {
        if (isDescendant(t1.source, t2.source)) {
          transitionsToRemove.add(t2);
        } else {
          t1Preempted = true;
          break;
        }
      }
    }
    if (!t1Preempted) {
      for (const t3 of transitionsToRemove) {
        filteredTransitions.delete(t3);
      }
      filteredTransitions.add(t1);
    }
  }
  return Array.from(filteredTransitions);
}
function findLeastCommonAncestor(stateNodes) {
  const [head, ...tail] = stateNodes;
  for (const ancestor of getProperAncestors(head, void 0)) {
    if (tail.every((sn) => isDescendant(sn, ancestor))) {
      return ancestor;
    }
  }
}
function getEffectiveTargetStates(transition, historyValue) {
  if (!transition.target) {
    return [];
  }
  const targets = /* @__PURE__ */ new Set();
  for (const targetNode of transition.target) {
    if (isHistoryNode(targetNode)) {
      if (historyValue[targetNode.id]) {
        for (const node of historyValue[targetNode.id]) {
          targets.add(node);
        }
      } else {
        for (const node of getEffectiveTargetStates(resolveHistoryDefaultTransition(targetNode), historyValue)) {
          targets.add(node);
        }
      }
    } else {
      targets.add(targetNode);
    }
  }
  return [...targets];
}
function getTransitionDomain(transition, historyValue) {
  const targetStates = getEffectiveTargetStates(transition, historyValue);
  if (!targetStates) {
    return;
  }
  if (!transition.reenter && targetStates.every((target) => target === transition.source || isDescendant(target, transition.source))) {
    return transition.source;
  }
  const lca = findLeastCommonAncestor(targetStates.concat(transition.source));
  if (lca) {
    return lca;
  }
  if (transition.reenter) {
    return;
  }
  return transition.source.machine.root;
}
function computeExitSet(transitions, stateNodeSet, historyValue) {
  const statesToExit = /* @__PURE__ */ new Set();
  for (const t of transitions) {
    if (t.target?.length) {
      const domain = getTransitionDomain(t, historyValue);
      if (t.reenter && t.source === domain) {
        statesToExit.add(domain);
      }
      for (const stateNode of stateNodeSet) {
        if (isDescendant(stateNode, domain)) {
          statesToExit.add(stateNode);
        }
      }
    }
  }
  return [...statesToExit];
}
function areStateNodeCollectionsEqual(prevStateNodes, nextStateNodeSet) {
  if (prevStateNodes.length !== nextStateNodeSet.size) {
    return false;
  }
  for (const node of prevStateNodes) {
    if (!nextStateNodeSet.has(node)) {
      return false;
    }
  }
  return true;
}
function microstep(transitions, currentSnapshot, actorScope, event, isInitial, internalQueue) {
  if (!transitions.length) {
    return currentSnapshot;
  }
  const mutStateNodeSet = new Set(currentSnapshot._nodes);
  let historyValue = currentSnapshot.historyValue;
  const filteredTransitions = removeConflictingTransitions(transitions, mutStateNodeSet, historyValue);
  let nextState = currentSnapshot;
  if (!isInitial) {
    [nextState, historyValue] = exitStates(nextState, event, actorScope, filteredTransitions, mutStateNodeSet, historyValue, internalQueue);
  }
  nextState = resolveActionsAndContext(nextState, event, actorScope, filteredTransitions.flatMap((t) => t.actions), internalQueue);
  nextState = enterStates(nextState, event, actorScope, filteredTransitions, mutStateNodeSet, internalQueue, historyValue, isInitial);
  const nextStateNodes = [...mutStateNodeSet];
  if (nextState.status === "done") {
    nextState = resolveActionsAndContext(nextState, event, actorScope, nextStateNodes.sort((a, b) => b.order - a.order).flatMap((state) => state.exit), internalQueue);
  }
  try {
    if (historyValue === currentSnapshot.historyValue && areStateNodeCollectionsEqual(currentSnapshot._nodes, mutStateNodeSet)) {
      return nextState;
    }
    return cloneMachineSnapshot(nextState, {
      _nodes: nextStateNodes,
      historyValue
    });
  } catch (e) {
    throw e;
  }
}
function getMachineOutput(snapshot, event, actorScope, rootNode, rootCompletionNode) {
  if (rootNode.output === void 0) {
    return;
  }
  const doneStateEvent = createDoneStateEvent(rootCompletionNode.id, rootCompletionNode.output !== void 0 && rootCompletionNode.parent ? resolveOutput(rootCompletionNode.output, snapshot.context, event, actorScope.self) : void 0);
  return resolveOutput(rootNode.output, snapshot.context, doneStateEvent, actorScope.self);
}
function enterStates(currentSnapshot, event, actorScope, filteredTransitions, mutStateNodeSet, internalQueue, historyValue, isInitial) {
  let nextSnapshot = currentSnapshot;
  const statesToEnter = /* @__PURE__ */ new Set();
  const statesForDefaultEntry = /* @__PURE__ */ new Set();
  computeEntrySet(filteredTransitions, historyValue, statesForDefaultEntry, statesToEnter);
  if (isInitial) {
    statesForDefaultEntry.add(currentSnapshot.machine.root);
  }
  const completedNodes = /* @__PURE__ */ new Set();
  for (const stateNodeToEnter of [...statesToEnter].sort((a, b) => a.order - b.order)) {
    mutStateNodeSet.add(stateNodeToEnter);
    const actions = [];
    actions.push(...stateNodeToEnter.entry);
    for (const invokeDef of stateNodeToEnter.invoke) {
      actions.push(spawnChild(invokeDef.src, {
        ...invokeDef,
        syncSnapshot: !!invokeDef.onSnapshot
      }));
    }
    if (statesForDefaultEntry.has(stateNodeToEnter)) {
      const initialActions = stateNodeToEnter.initial.actions;
      actions.push(...initialActions);
    }
    nextSnapshot = resolveActionsAndContext(nextSnapshot, event, actorScope, actions, internalQueue, stateNodeToEnter.invoke.map((invokeDef) => invokeDef.id));
    if (stateNodeToEnter.type === "final") {
      const parent = stateNodeToEnter.parent;
      let ancestorMarker = parent?.type === "parallel" ? parent : parent?.parent;
      let rootCompletionNode = ancestorMarker || stateNodeToEnter;
      if (parent?.type === "compound") {
        internalQueue.push(createDoneStateEvent(parent.id, stateNodeToEnter.output !== void 0 ? resolveOutput(stateNodeToEnter.output, nextSnapshot.context, event, actorScope.self) : void 0));
      }
      while (ancestorMarker?.type === "parallel" && !completedNodes.has(ancestorMarker) && isInFinalState(mutStateNodeSet, ancestorMarker)) {
        completedNodes.add(ancestorMarker);
        internalQueue.push(createDoneStateEvent(ancestorMarker.id));
        rootCompletionNode = ancestorMarker;
        ancestorMarker = ancestorMarker.parent;
      }
      if (ancestorMarker) {
        continue;
      }
      nextSnapshot = cloneMachineSnapshot(nextSnapshot, {
        status: "done",
        output: getMachineOutput(nextSnapshot, event, actorScope, nextSnapshot.machine.root, rootCompletionNode)
      });
    }
  }
  return nextSnapshot;
}
function computeEntrySet(transitions, historyValue, statesForDefaultEntry, statesToEnter) {
  for (const t of transitions) {
    const domain = getTransitionDomain(t, historyValue);
    for (const s of t.target || []) {
      if (!isHistoryNode(s) && // if the target is different than the source then it will *definitely* be entered
      (t.source !== s || // we know that the domain can't lie within the source
      // if it's different than the source then it's outside of it and it means that the target has to be entered as well
      t.source !== domain || // reentering transitions always enter the target, even if it's the source itself
      t.reenter)) {
        statesToEnter.add(s);
        statesForDefaultEntry.add(s);
      }
      addDescendantStatesToEnter(s, historyValue, statesForDefaultEntry, statesToEnter);
    }
    const targetStates = getEffectiveTargetStates(t, historyValue);
    for (const s of targetStates) {
      const ancestors = getProperAncestors(s, domain);
      if (domain?.type === "parallel") {
        ancestors.push(domain);
      }
      addAncestorStatesToEnter(statesToEnter, historyValue, statesForDefaultEntry, ancestors, !t.source.parent && t.reenter ? void 0 : domain);
    }
  }
}
function addDescendantStatesToEnter(stateNode, historyValue, statesForDefaultEntry, statesToEnter) {
  if (isHistoryNode(stateNode)) {
    if (historyValue[stateNode.id]) {
      const historyStateNodes = historyValue[stateNode.id];
      for (const s of historyStateNodes) {
        statesToEnter.add(s);
        addDescendantStatesToEnter(s, historyValue, statesForDefaultEntry, statesToEnter);
      }
      for (const s of historyStateNodes) {
        addProperAncestorStatesToEnter(s, stateNode.parent, statesToEnter, historyValue, statesForDefaultEntry);
      }
    } else {
      const historyDefaultTransition = resolveHistoryDefaultTransition(stateNode);
      for (const s of historyDefaultTransition.target) {
        statesToEnter.add(s);
        if (historyDefaultTransition === stateNode.parent?.initial) {
          statesForDefaultEntry.add(stateNode.parent);
        }
        addDescendantStatesToEnter(s, historyValue, statesForDefaultEntry, statesToEnter);
      }
      for (const s of historyDefaultTransition.target) {
        addProperAncestorStatesToEnter(s, stateNode.parent, statesToEnter, historyValue, statesForDefaultEntry);
      }
    }
  } else {
    if (stateNode.type === "compound") {
      const [initialState] = stateNode.initial.target;
      if (!isHistoryNode(initialState)) {
        statesToEnter.add(initialState);
        statesForDefaultEntry.add(initialState);
      }
      addDescendantStatesToEnter(initialState, historyValue, statesForDefaultEntry, statesToEnter);
      addProperAncestorStatesToEnter(initialState, stateNode, statesToEnter, historyValue, statesForDefaultEntry);
    } else {
      if (stateNode.type === "parallel") {
        for (const child of getChildren(stateNode).filter((sn) => !isHistoryNode(sn))) {
          if (![...statesToEnter].some((s) => isDescendant(s, child))) {
            if (!isHistoryNode(child)) {
              statesToEnter.add(child);
              statesForDefaultEntry.add(child);
            }
            addDescendantStatesToEnter(child, historyValue, statesForDefaultEntry, statesToEnter);
          }
        }
      }
    }
  }
}
function addAncestorStatesToEnter(statesToEnter, historyValue, statesForDefaultEntry, ancestors, reentrancyDomain) {
  for (const anc of ancestors) {
    if (!reentrancyDomain || isDescendant(anc, reentrancyDomain)) {
      statesToEnter.add(anc);
    }
    if (anc.type === "parallel") {
      for (const child of getChildren(anc).filter((sn) => !isHistoryNode(sn))) {
        if (![...statesToEnter].some((s) => isDescendant(s, child))) {
          statesToEnter.add(child);
          addDescendantStatesToEnter(child, historyValue, statesForDefaultEntry, statesToEnter);
        }
      }
    }
  }
}
function addProperAncestorStatesToEnter(stateNode, toStateNode, statesToEnter, historyValue, statesForDefaultEntry) {
  addAncestorStatesToEnter(statesToEnter, historyValue, statesForDefaultEntry, getProperAncestors(stateNode, toStateNode));
}
function exitStates(currentSnapshot, event, actorScope, transitions, mutStateNodeSet, historyValue, internalQueue) {
  let nextSnapshot = currentSnapshot;
  const statesToExit = computeExitSet(transitions, mutStateNodeSet, historyValue);
  statesToExit.sort((a, b) => b.order - a.order);
  let changedHistory;
  for (const exitStateNode of statesToExit) {
    for (const historyNode of getHistoryNodes(exitStateNode)) {
      let predicate;
      if (historyNode.history === "deep") {
        predicate = (sn) => isAtomicStateNode(sn) && isDescendant(sn, exitStateNode);
      } else {
        predicate = (sn) => {
          return sn.parent === exitStateNode;
        };
      }
      changedHistory ??= {
        ...historyValue
      };
      changedHistory[historyNode.id] = Array.from(mutStateNodeSet).filter(predicate);
    }
  }
  for (const s of statesToExit) {
    nextSnapshot = resolveActionsAndContext(nextSnapshot, event, actorScope, [...s.exit, ...s.invoke.map((def) => stopChild(def.id))], internalQueue);
    mutStateNodeSet.delete(s);
  }
  return [nextSnapshot, changedHistory || historyValue];
}
function resolveAndExecuteActionsWithContext(currentSnapshot, event, actorScope, actions, extra, retries) {
  const {
    machine
  } = currentSnapshot;
  let intermediateSnapshot = currentSnapshot;
  for (const action of actions) {
    let executeAction = function() {
      actorScope.system._sendInspectionEvent({
        type: "@xstate.action",
        actorRef: actorScope.self,
        action: {
          type: typeof action === "string" ? action : typeof action === "object" ? action.type : action.name || "(anonymous)",
          params: actionParams
        }
      });
      resolvedAction(actionArgs, actionParams);
    };
    const isInline = typeof action === "function";
    const resolvedAction = isInline ? action : (
      // the existing type of `.actions` assumes non-nullable `TExpressionAction`
      // it's fine to cast this here to get a common type and lack of errors in the rest of the code
      // our logic below makes sure that we call those 2 "variants" correctly
      machine.implementations.actions[typeof action === "string" ? action : action.type]
    );
    if (!resolvedAction) {
      continue;
    }
    const actionArgs = {
      context: intermediateSnapshot.context,
      event,
      self: actorScope.self,
      system: actorScope.system
    };
    const actionParams = isInline || typeof action === "string" ? void 0 : "params" in action ? typeof action.params === "function" ? action.params({
      context: intermediateSnapshot.context,
      event
    }) : action.params : void 0;
    if (!("resolve" in resolvedAction)) {
      if (actorScope.self._processingStatus === ProcessingStatus.Running) {
        executeAction();
      } else {
        actorScope.defer(() => {
          executeAction();
        });
      }
      continue;
    }
    const builtinAction = resolvedAction;
    const [nextState, params, actions2] = builtinAction.resolve(
      actorScope,
      intermediateSnapshot,
      actionArgs,
      actionParams,
      resolvedAction,
      // this holds all params
      extra
    );
    intermediateSnapshot = nextState;
    if ("retryResolve" in builtinAction) {
      retries?.push([builtinAction, params]);
    }
    if ("execute" in builtinAction) {
      if (actorScope.self._processingStatus === ProcessingStatus.Running) {
        builtinAction.execute(actorScope, params);
      } else {
        actorScope.defer(builtinAction.execute.bind(null, actorScope, params));
      }
    }
    if (actions2) {
      intermediateSnapshot = resolveAndExecuteActionsWithContext(intermediateSnapshot, event, actorScope, actions2, extra, retries);
    }
  }
  return intermediateSnapshot;
}
function resolveActionsAndContext(currentSnapshot, event, actorScope, actions, internalQueue, deferredActorIds) {
  const retries = deferredActorIds ? [] : void 0;
  const nextState = resolveAndExecuteActionsWithContext(currentSnapshot, event, actorScope, actions, {
    internalQueue,
    deferredActorIds
  }, retries);
  retries?.forEach(([builtinAction, params]) => {
    builtinAction.retryResolve(actorScope, nextState, params);
  });
  return nextState;
}
function macrostep(snapshot, event, actorScope, internalQueue = []) {
  let nextSnapshot = snapshot;
  const microstates = [];
  function addMicrostate(microstate, event2, transitions) {
    actorScope.system._sendInspectionEvent({
      type: "@xstate.microstep",
      actorRef: actorScope.self,
      event: event2,
      snapshot: microstate,
      _transitions: transitions
    });
    microstates.push(microstate);
  }
  if (event.type === XSTATE_STOP) {
    nextSnapshot = cloneMachineSnapshot(stopChildren(nextSnapshot, event, actorScope), {
      status: "stopped"
    });
    addMicrostate(nextSnapshot, event, []);
    return {
      snapshot: nextSnapshot,
      microstates
    };
  }
  let nextEvent = event;
  if (nextEvent.type !== XSTATE_INIT) {
    const currentEvent = nextEvent;
    const isErr = isErrorActorEvent(currentEvent);
    const transitions = selectTransitions(currentEvent, nextSnapshot);
    if (isErr && !transitions.length) {
      nextSnapshot = cloneMachineSnapshot(snapshot, {
        status: "error",
        error: currentEvent.error
      });
      addMicrostate(nextSnapshot, currentEvent, []);
      return {
        snapshot: nextSnapshot,
        microstates
      };
    }
    nextSnapshot = microstep(
      transitions,
      snapshot,
      actorScope,
      nextEvent,
      false,
      // isInitial
      internalQueue
    );
    addMicrostate(nextSnapshot, currentEvent, transitions);
  }
  let shouldSelectEventlessTransitions = true;
  while (nextSnapshot.status === "active") {
    let enabledTransitions = shouldSelectEventlessTransitions ? selectEventlessTransitions(nextSnapshot, nextEvent) : [];
    const previousState = enabledTransitions.length ? nextSnapshot : void 0;
    if (!enabledTransitions.length) {
      if (!internalQueue.length) {
        break;
      }
      nextEvent = internalQueue.shift();
      enabledTransitions = selectTransitions(nextEvent, nextSnapshot);
    }
    nextSnapshot = microstep(enabledTransitions, nextSnapshot, actorScope, nextEvent, false, internalQueue);
    shouldSelectEventlessTransitions = nextSnapshot !== previousState;
    addMicrostate(nextSnapshot, nextEvent, enabledTransitions);
  }
  if (nextSnapshot.status !== "active") {
    stopChildren(nextSnapshot, nextEvent, actorScope);
  }
  return {
    snapshot: nextSnapshot,
    microstates
  };
}
function stopChildren(nextState, event, actorScope) {
  return resolveActionsAndContext(nextState, event, actorScope, Object.values(nextState.children).map((child) => stopChild(child)), []);
}
function selectTransitions(event, nextState) {
  return nextState.machine.getTransitionData(nextState, event);
}
function selectEventlessTransitions(nextState, event) {
  const enabledTransitionSet = /* @__PURE__ */ new Set();
  const atomicStates = nextState._nodes.filter(isAtomicStateNode);
  for (const stateNode of atomicStates) {
    loop: for (const s of [stateNode].concat(getProperAncestors(stateNode, void 0))) {
      if (!s.always) {
        continue;
      }
      for (const transition of s.always) {
        if (transition.guard === void 0 || evaluateGuard(transition.guard, nextState.context, event, nextState)) {
          enabledTransitionSet.add(transition);
          break loop;
        }
      }
    }
  }
  return removeConflictingTransitions(Array.from(enabledTransitionSet), new Set(nextState._nodes), nextState.historyValue);
}
function resolveStateValue(rootNode, stateValue) {
  const allStateNodes = getAllStateNodes(getStateNodes(rootNode, stateValue));
  return getStateValue(rootNode, [...allStateNodes]);
}
function isMachineSnapshot(value) {
  return !!value && typeof value === "object" && "machine" in value && "value" in value;
}
var machineSnapshotMatches = function matches(testValue) {
  return matchesState(testValue, this.value);
};
var machineSnapshotHasTag = function hasTag(tag) {
  return this.tags.has(tag);
};
var machineSnapshotCan = function can(event) {
  const transitionData = this.machine.getTransitionData(this, event);
  return !!transitionData?.length && // Check that at least one transition is not forbidden
  transitionData.some((t) => t.target !== void 0 || t.actions.length);
};
var machineSnapshotToJSON = function toJSON() {
  const {
    _nodes: nodes,
    tags,
    machine,
    getMeta: getMeta2,
    toJSON: toJSON2,
    can: can2,
    hasTag: hasTag2,
    matches: matches2,
    ...jsonValues
  } = this;
  return {
    ...jsonValues,
    tags: Array.from(tags)
  };
};
var machineSnapshotGetMeta = function getMeta() {
  return this._nodes.reduce((acc, stateNode) => {
    if (stateNode.meta !== void 0) {
      acc[stateNode.id] = stateNode.meta;
    }
    return acc;
  }, {});
};
function createMachineSnapshot(config, machine) {
  return {
    status: config.status,
    output: config.output,
    error: config.error,
    machine,
    context: config.context,
    _nodes: config._nodes,
    value: getStateValue(machine.root, config._nodes),
    tags: new Set(config._nodes.flatMap((sn) => sn.tags)),
    children: config.children,
    historyValue: config.historyValue || {},
    matches: machineSnapshotMatches,
    hasTag: machineSnapshotHasTag,
    can: machineSnapshotCan,
    getMeta: machineSnapshotGetMeta,
    toJSON: machineSnapshotToJSON
  };
}
function cloneMachineSnapshot(snapshot, config = {}) {
  return createMachineSnapshot({
    ...snapshot,
    ...config
  }, snapshot.machine);
}
function getPersistedSnapshot(snapshot, options) {
  const {
    _nodes: nodes,
    tags,
    machine,
    children,
    context,
    can: can2,
    hasTag: hasTag2,
    matches: matches2,
    getMeta: getMeta2,
    toJSON: toJSON2,
    ...jsonValues
  } = snapshot;
  const childrenJson = {};
  for (const id in children) {
    const child = children[id];
    childrenJson[id] = {
      snapshot: child.getPersistedSnapshot(options),
      src: child.src,
      systemId: child._systemId,
      syncSnapshot: child._syncSnapshot
    };
  }
  const persisted = {
    ...jsonValues,
    context: persistContext(context),
    children: childrenJson
  };
  return persisted;
}
function persistContext(contextPart) {
  let copy;
  for (const key in contextPart) {
    const value = contextPart[key];
    if (value && typeof value === "object") {
      if ("sessionId" in value && "send" in value && "ref" in value) {
        copy ??= Array.isArray(contextPart) ? contextPart.slice() : {
          ...contextPart
        };
        copy[key] = {
          xstate$$type: $$ACTOR_TYPE,
          id: value.id
        };
      } else {
        const result = persistContext(value);
        if (result !== value) {
          copy ??= Array.isArray(contextPart) ? contextPart.slice() : {
            ...contextPart
          };
          copy[key] = result;
        }
      }
    }
  }
  return copy ?? contextPart;
}
function resolveRaise(_, snapshot, args, actionParams, {
  event: eventOrExpr,
  id,
  delay
}, {
  internalQueue
}) {
  const delaysMap = snapshot.machine.implementations.delays;
  if (typeof eventOrExpr === "string") {
    throw new Error(`Only event objects may be used with raise; use raise({ type: "${eventOrExpr}" }) instead`);
  }
  const resolvedEvent = typeof eventOrExpr === "function" ? eventOrExpr(args, actionParams) : eventOrExpr;
  let resolvedDelay;
  if (typeof delay === "string") {
    const configDelay = delaysMap && delaysMap[delay];
    resolvedDelay = typeof configDelay === "function" ? configDelay(args, actionParams) : configDelay;
  } else {
    resolvedDelay = typeof delay === "function" ? delay(args, actionParams) : delay;
  }
  if (typeof resolvedDelay !== "number") {
    internalQueue.push(resolvedEvent);
  }
  return [snapshot, {
    event: resolvedEvent,
    id,
    delay: resolvedDelay
  }];
}
function executeRaise(actorScope, params) {
  const {
    event,
    delay,
    id
  } = params;
  if (typeof delay === "number") {
    actorScope.defer(() => {
      const self2 = actorScope.self;
      actorScope.system.scheduler.schedule(self2, self2, event, delay, id);
    });
    return;
  }
}
function raise(eventOrExpr, options) {
  function raise2(args, params) {
  }
  raise2.type = "xstate.raise";
  raise2.event = eventOrExpr;
  raise2.id = options?.id;
  raise2.delay = options?.delay;
  raise2.resolve = resolveRaise;
  raise2.execute = executeRaise;
  return raise2;
}

// ../../../../node_modules/.pnpm/xstate@5.12.0/node_modules/xstate/actors/dist/xstate-actors.esm.js
function fromTransition(transition, initialContext) {
  return {
    config: transition,
    transition: (snapshot, event, actorScope) => {
      return {
        ...snapshot,
        context: transition(snapshot.context, event, actorScope)
      };
    },
    getInitialSnapshot: (_, input) => {
      return {
        status: "active",
        output: void 0,
        error: void 0,
        context: typeof initialContext === "function" ? initialContext({
          input
        }) : initialContext
      };
    },
    getPersistedSnapshot: (snapshot) => snapshot,
    restoreSnapshot: (snapshot) => snapshot
  };
}
var emptyLogic = fromTransition((_) => void 0, void 0);

// ../../../../node_modules/.pnpm/xstate@5.12.0/node_modules/xstate/dist/log-cd22d72c.esm.js
function createSpawner(actorScope, {
  machine,
  context
}, event, spawnedChildren) {
  const spawn = (src, options = {}) => {
    const {
      systemId,
      input
    } = options;
    if (typeof src === "string") {
      const logic = resolveReferencedActor(machine, src);
      if (!logic) {
        throw new Error(`Actor logic '${src}' not implemented in machine '${machine.id}'`);
      }
      const actorRef = createActor(logic, {
        id: options.id,
        parent: actorScope.self,
        syncSnapshot: options.syncSnapshot,
        input: typeof input === "function" ? input({
          context,
          event,
          self: actorScope.self
        }) : input,
        src,
        systemId
      });
      spawnedChildren[actorRef.id] = actorRef;
      return actorRef;
    } else {
      const actorRef = createActor(src, {
        id: options.id,
        parent: actorScope.self,
        syncSnapshot: options.syncSnapshot,
        input: options.input,
        src,
        systemId
      });
      return actorRef;
    }
  };
  return (src, options) => {
    const actorRef = spawn(src, options);
    spawnedChildren[actorRef.id] = actorRef;
    actorScope.defer(() => {
      if (actorRef._processingStatus === ProcessingStatus.Stopped) {
        return;
      }
      actorRef.start();
    });
    return actorRef;
  };
}
function resolveAssign(actorScope, snapshot, actionArgs, actionParams, {
  assignment
}) {
  if (!snapshot.context) {
    throw new Error("Cannot assign to undefined `context`. Ensure that `context` is defined in the machine config.");
  }
  const spawnedChildren = {};
  const assignArgs = {
    context: snapshot.context,
    event: actionArgs.event,
    spawn: createSpawner(actorScope, snapshot, actionArgs.event, spawnedChildren),
    self: actorScope.self,
    system: actorScope.system
  };
  let partialUpdate = {};
  if (typeof assignment === "function") {
    partialUpdate = assignment(assignArgs, actionParams);
  } else {
    for (const key of Object.keys(assignment)) {
      const propAssignment = assignment[key];
      partialUpdate[key] = typeof propAssignment === "function" ? propAssignment(assignArgs, actionParams) : propAssignment;
    }
  }
  const updatedContext = Object.assign({}, snapshot.context, partialUpdate);
  return [cloneMachineSnapshot(snapshot, {
    context: updatedContext,
    children: Object.keys(spawnedChildren).length ? {
      ...snapshot.children,
      ...spawnedChildren
    } : snapshot.children
  })];
}
function assign(assignment) {
  function assign2(args, params) {
  }
  assign2.type = "xstate.assign";
  assign2.assignment = assignment;
  assign2.resolve = resolveAssign;
  return assign2;
}

// ../../../../node_modules/.pnpm/xstate@5.12.0/node_modules/xstate/dist/xstate.esm.js
var cache = /* @__PURE__ */ new WeakMap();
function memo(object, key, fn) {
  let memoizedData = cache.get(object);
  if (!memoizedData) {
    memoizedData = {
      [key]: fn()
    };
    cache.set(object, memoizedData);
  } else if (!(key in memoizedData)) {
    memoizedData[key] = fn();
  }
  return memoizedData[key];
}
var EMPTY_OBJECT = {};
var toSerializableAction = (action) => {
  if (typeof action === "string") {
    return {
      type: action
    };
  }
  if (typeof action === "function") {
    if ("resolve" in action) {
      return {
        type: action.type
      };
    }
    return {
      type: action.name
    };
  }
  return action;
};
var StateNode = class _StateNode {
  constructor(config, options) {
    this.config = config;
    this.key = void 0;
    this.id = void 0;
    this.type = void 0;
    this.path = void 0;
    this.states = void 0;
    this.history = void 0;
    this.entry = void 0;
    this.exit = void 0;
    this.parent = void 0;
    this.machine = void 0;
    this.meta = void 0;
    this.output = void 0;
    this.order = -1;
    this.description = void 0;
    this.tags = [];
    this.transitions = void 0;
    this.always = void 0;
    this.parent = options._parent;
    this.key = options._key;
    this.machine = options._machine;
    this.path = this.parent ? this.parent.path.concat(this.key) : [];
    this.id = this.config.id || [this.machine.id, ...this.path].join(STATE_DELIMITER);
    this.type = this.config.type || (this.config.states && Object.keys(this.config.states).length ? "compound" : this.config.history ? "history" : "atomic");
    this.description = this.config.description;
    this.order = this.machine.idMap.size;
    this.machine.idMap.set(this.id, this);
    this.states = this.config.states ? mapValues(this.config.states, (stateConfig, key) => {
      const stateNode = new _StateNode(stateConfig, {
        _parent: this,
        _key: key,
        _machine: this.machine
      });
      return stateNode;
    }) : EMPTY_OBJECT;
    if (this.type === "compound" && !this.config.initial) {
      throw new Error(`No initial state specified for compound state node "#${this.id}". Try adding { initial: "${Object.keys(this.states)[0]}" } to the state config.`);
    }
    this.history = this.config.history === true ? "shallow" : this.config.history || false;
    this.entry = toArray(this.config.entry).slice();
    this.exit = toArray(this.config.exit).slice();
    this.meta = this.config.meta;
    this.output = this.type === "final" || !this.parent ? this.config.output : void 0;
    this.tags = toArray(config.tags).slice();
  }
  /** @internal */
  _initialize() {
    this.transitions = formatTransitions(this);
    if (this.config.always) {
      this.always = toTransitionConfigArray(this.config.always).map((t) => formatTransition(this, NULL_EVENT, t));
    }
    Object.keys(this.states).forEach((key) => {
      this.states[key]._initialize();
    });
  }
  /**
   * The well-structured state node definition.
   */
  get definition() {
    return {
      id: this.id,
      key: this.key,
      version: this.machine.version,
      type: this.type,
      initial: this.initial ? {
        target: this.initial.target,
        source: this,
        actions: this.initial.actions.map(toSerializableAction),
        eventType: null,
        reenter: false,
        toJSON: () => ({
          target: this.initial.target.map((t) => `#${t.id}`),
          source: `#${this.id}`,
          actions: this.initial.actions.map(toSerializableAction),
          eventType: null
        })
      } : void 0,
      history: this.history,
      states: mapValues(this.states, (state) => {
        return state.definition;
      }),
      on: this.on,
      transitions: [...this.transitions.values()].flat().map((t) => ({
        ...t,
        actions: t.actions.map(toSerializableAction)
      })),
      entry: this.entry.map(toSerializableAction),
      exit: this.exit.map(toSerializableAction),
      meta: this.meta,
      order: this.order || -1,
      output: this.output,
      invoke: this.invoke,
      description: this.description,
      tags: this.tags
    };
  }
  /** @internal */
  toJSON() {
    return this.definition;
  }
  /**
   * The logic invoked as actors by this state node.
   */
  get invoke() {
    return memo(this, "invoke", () => toArray(this.config.invoke).map((invokeConfig, i) => {
      const {
        src,
        systemId
      } = invokeConfig;
      const resolvedId = invokeConfig.id ?? createInvokeId(this.id, i);
      const resolvedSrc = typeof src === "string" ? src : `xstate.invoke.${createInvokeId(this.id, i)}`;
      return {
        ...invokeConfig,
        src: resolvedSrc,
        id: resolvedId,
        systemId,
        toJSON() {
          const {
            onDone,
            onError,
            ...invokeDefValues
          } = invokeConfig;
          return {
            ...invokeDefValues,
            type: "xstate.invoke",
            src: resolvedSrc,
            id: resolvedId
          };
        }
      };
    }));
  }
  /**
   * The mapping of events to transitions.
   */
  get on() {
    return memo(this, "on", () => {
      const transitions = this.transitions;
      return [...transitions].flatMap(([descriptor, t]) => t.map((t2) => [descriptor, t2])).reduce((map, [descriptor, transition]) => {
        map[descriptor] = map[descriptor] || [];
        map[descriptor].push(transition);
        return map;
      }, {});
    });
  }
  get after() {
    return memo(this, "delayedTransitions", () => getDelayedTransitions(this));
  }
  get initial() {
    return memo(this, "initial", () => formatInitialTransition(this, this.config.initial));
  }
  /** @internal */
  next(snapshot, event) {
    const eventType = event.type;
    const actions = [];
    let selectedTransition;
    const candidates = memo(this, `candidates-${eventType}`, () => getCandidates(this, eventType));
    for (const candidate of candidates) {
      const {
        guard
      } = candidate;
      const resolvedContext = snapshot.context;
      let guardPassed = false;
      try {
        guardPassed = !guard || evaluateGuard(guard, resolvedContext, event, snapshot);
      } catch (err) {
        const guardType = typeof guard === "string" ? guard : typeof guard === "object" ? guard.type : void 0;
        throw new Error(`Unable to evaluate guard ${guardType ? `'${guardType}' ` : ""}in transition for event '${eventType}' in state node '${this.id}':
${err.message}`);
      }
      if (guardPassed) {
        actions.push(...candidate.actions);
        selectedTransition = candidate;
        break;
      }
    }
    return selectedTransition ? [selectedTransition] : void 0;
  }
  /**
   * All the event types accepted by this state node and its descendants.
   */
  get events() {
    return memo(this, "events", () => {
      const {
        states
      } = this;
      const events = new Set(this.ownEvents);
      if (states) {
        for (const stateId of Object.keys(states)) {
          const state = states[stateId];
          if (state.states) {
            for (const event of state.events) {
              events.add(`${event}`);
            }
          }
        }
      }
      return Array.from(events);
    });
  }
  /**
   * All the events that have transitions directly from this state node.
   *
   * Excludes any inert events.
   */
  get ownEvents() {
    const events = new Set([...this.transitions.keys()].filter((descriptor) => {
      return this.transitions.get(descriptor).some((transition) => !(!transition.target && !transition.actions.length && !transition.reenter));
    }));
    return Array.from(events);
  }
};
var STATE_IDENTIFIER2 = "#";
var StateMachine = class _StateMachine {
  constructor(config, implementations) {
    this.config = config;
    this.version = void 0;
    this.schemas = void 0;
    this.implementations = void 0;
    this.__xstatenode = true;
    this.idMap = /* @__PURE__ */ new Map();
    this.root = void 0;
    this.id = void 0;
    this.states = void 0;
    this.events = void 0;
    this.__TResolvedTypesMeta = void 0;
    this.id = config.id || "(machine)";
    this.implementations = {
      actors: implementations?.actors ?? {},
      actions: implementations?.actions ?? {},
      delays: implementations?.delays ?? {},
      guards: implementations?.guards ?? {}
    };
    this.version = this.config.version;
    this.schemas = this.config.schemas;
    this.transition = this.transition.bind(this);
    this.getInitialSnapshot = this.getInitialSnapshot.bind(this);
    this.getPersistedSnapshot = this.getPersistedSnapshot.bind(this);
    this.restoreSnapshot = this.restoreSnapshot.bind(this);
    this.start = this.start.bind(this);
    this.root = new StateNode(config, {
      _key: this.id,
      _machine: this
    });
    this.root._initialize();
    this.states = this.root.states;
    this.events = this.root.events;
  }
  /**
   * Clones this state machine with the provided implementations
   * and merges the `context` (if provided).
   *
   * @param implementations Options (`actions`, `guards`, `actors`, `delays`, `context`)
   *  to recursively merge with the existing options.
   *
   * @returns A new `StateMachine` instance with the provided implementations.
   */
  provide(implementations) {
    const {
      actions,
      guards,
      actors,
      delays
    } = this.implementations;
    return new _StateMachine(this.config, {
      actions: {
        ...actions,
        ...implementations.actions
      },
      guards: {
        ...guards,
        ...implementations.guards
      },
      actors: {
        ...actors,
        ...implementations.actors
      },
      delays: {
        ...delays,
        ...implementations.delays
      }
    });
  }
  resolveState(config) {
    const resolvedStateValue = resolveStateValue(this.root, config.value);
    const nodeSet = getAllStateNodes(getStateNodes(this.root, resolvedStateValue));
    return createMachineSnapshot({
      _nodes: [...nodeSet],
      context: config.context || {},
      children: {},
      status: isInFinalState(nodeSet, this.root) ? "done" : config.status || "active",
      output: config.output,
      error: config.error,
      historyValue: config.historyValue
    }, this);
  }
  /**
   * Determines the next snapshot given the current `snapshot` and received `event`.
   * Calculates a full macrostep from all microsteps.
   *
   * @param snapshot The current snapshot
   * @param event The received event
   */
  transition(snapshot, event, actorScope) {
    return macrostep(snapshot, event, actorScope).snapshot;
  }
  /**
   * Determines the next state given the current `state` and `event`.
   * Calculates a microstep.
   *
   * @param state The current state
   * @param event The received event
   */
  microstep(snapshot, event, actorScope) {
    return macrostep(snapshot, event, actorScope).microstates;
  }
  getTransitionData(snapshot, event) {
    return transitionNode(this.root, snapshot.value, snapshot, event) || [];
  }
  /**
   * The initial state _before_ evaluating any microsteps.
   * This "pre-initial" state is provided to initial actions executed in the initial state.
   */
  getPreInitialState(actorScope, initEvent, internalQueue) {
    const {
      context
    } = this.config;
    const preInitial = createMachineSnapshot({
      context: typeof context !== "function" && context ? context : {},
      _nodes: [this.root],
      children: {},
      status: "active"
    }, this);
    if (typeof context === "function") {
      const assignment = ({
        spawn,
        event,
        self: self2
      }) => context({
        spawn,
        input: event.input,
        self: self2
      });
      return resolveActionsAndContext(preInitial, initEvent, actorScope, [assign(assignment)], internalQueue);
    }
    return preInitial;
  }
  /**
   * Returns the initial `State` instance, with reference to `self` as an `ActorRef`.
   */
  getInitialSnapshot(actorScope, input) {
    const initEvent = createInitEvent(input);
    const internalQueue = [];
    const preInitialState = this.getPreInitialState(actorScope, initEvent, internalQueue);
    const nextState = microstep([{
      target: [...getInitialStateNodes(this.root)],
      source: this.root,
      reenter: true,
      actions: [],
      eventType: null,
      toJSON: null
      // TODO: fix
    }], preInitialState, actorScope, initEvent, true, internalQueue);
    const {
      snapshot: macroState
    } = macrostep(nextState, initEvent, actorScope, internalQueue);
    return macroState;
  }
  start(snapshot) {
    Object.values(snapshot.children).forEach((child) => {
      if (child.getSnapshot().status === "active") {
        child.start();
      }
    });
  }
  getStateNodeById(stateId) {
    const fullPath = toStatePath(stateId);
    const relativePath = fullPath.slice(1);
    const resolvedStateId = isStateId(fullPath[0]) ? fullPath[0].slice(STATE_IDENTIFIER2.length) : fullPath[0];
    const stateNode = this.idMap.get(resolvedStateId);
    if (!stateNode) {
      throw new Error(`Child state node '#${resolvedStateId}' does not exist on machine '${this.id}'`);
    }
    return getStateNodeByPath(stateNode, relativePath);
  }
  get definition() {
    return this.root.definition;
  }
  toJSON() {
    return this.definition;
  }
  getPersistedSnapshot(snapshot, options) {
    return getPersistedSnapshot(snapshot, options);
  }
  restoreSnapshot(snapshot, _actorScope) {
    const children = {};
    const snapshotChildren = snapshot.children;
    Object.keys(snapshotChildren).forEach((actorId) => {
      const actorData = snapshotChildren[actorId];
      const childState = actorData.snapshot;
      const src = actorData.src;
      const logic = typeof src === "string" ? resolveReferencedActor(this, src) : src;
      if (!logic) {
        return;
      }
      const actorRef = createActor(logic, {
        id: actorId,
        parent: _actorScope.self,
        syncSnapshot: actorData.syncSnapshot,
        snapshot: childState,
        src,
        systemId: actorData.systemId
      });
      children[actorId] = actorRef;
    });
    const restoredSnapshot = createMachineSnapshot({
      ...snapshot,
      children,
      _nodes: Array.from(getAllStateNodes(getStateNodes(this.root, snapshot.value)))
    }, this);
    let seen = /* @__PURE__ */ new Set();
    function reviveContext(contextPart, children2) {
      if (seen.has(contextPart)) {
        return;
      }
      seen.add(contextPart);
      for (let key in contextPart) {
        const value = contextPart[key];
        if (value && typeof value === "object") {
          if ("xstate$$type" in value && value.xstate$$type === $$ACTOR_TYPE) {
            contextPart[key] = children2[value.id];
            continue;
          }
          reviveContext(value, children2);
        }
      }
    }
    reviveContext(restoredSnapshot.context, children);
    return restoredSnapshot;
  }
};
var defaultWaitForOptions = {
  timeout: Infinity
  // much more than 10 seconds
};
function waitFor(actorRef, predicate, options) {
  const resolvedOptions = {
    ...defaultWaitForOptions,
    ...options
  };
  return new Promise((res, rej) => {
    let done = false;
    const handle = resolvedOptions.timeout === Infinity ? void 0 : setTimeout(() => {
      sub.unsubscribe();
      rej(new Error(`Timeout of ${resolvedOptions.timeout} ms exceeded`));
    }, resolvedOptions.timeout);
    const dispose = () => {
      clearTimeout(handle);
      done = true;
      sub?.unsubscribe();
    };
    function checkEmitted(emitted) {
      if (predicate(emitted)) {
        dispose();
        res(emitted);
      }
    }
    let sub;
    checkEmitted(actorRef.getSnapshot());
    if (done) {
      return;
    }
    sub = actorRef.subscribe({
      next: checkEmitted,
      error: (err) => {
        dispose();
        rej(err);
      },
      complete: () => {
        dispose();
        rej(new Error(`Actor terminated without satisfying predicate`));
      }
    });
    if (done) {
      sub.unsubscribe();
    }
  });
}
function createMachine(config, implementations) {
  return new StateMachine(config, implementations);
}
function setup({
  schemas,
  actors,
  actions,
  guards,
  delays
}) {
  return {
    createMachine: (config) => createMachine({
      ...config,
      schemas
    }, {
      actors,
      actions,
      guards,
      delays
    })
  };
}
function assertEvent(event, type) {
  const types = toArray(type);
  if (!types.includes(event.type)) {
    const typesText = types.length === 1 ? `type "${types[0]}"` : `one of types "${types.join('", "')}"`;
    throw new Error(`Expected event ${JSON.stringify(event)} to have ${typesText}`);
  }
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/AutomergeUrl.js
import * as Uuid from "uuid";
import bs58check from "bs58check";
var urlPrefix = "automerge:";
var parseAutomergeUrl = (url) => {
  const regex = new RegExp(`^${urlPrefix}(\\w+)$`);
  const [, docMatch] = url.match(regex) || [];
  const documentId = docMatch;
  const binaryDocumentId = documentIdToBinary(documentId);
  if (!binaryDocumentId)
    throw new Error("Invalid document URL: " + url);
  return {
    /** unencoded DocumentId */
    binaryDocumentId,
    /** encoded DocumentId */
    documentId
  };
};
var stringifyAutomergeUrl = (arg) => {
  const documentId = arg instanceof Uint8Array || typeof arg === "string" ? arg : "documentId" in arg ? arg.documentId : void 0;
  const encodedDocumentId = documentId instanceof Uint8Array ? binaryToDocumentId(documentId) : typeof documentId === "string" ? documentId : void 0;
  if (encodedDocumentId === void 0)
    throw new Error("Invalid documentId: " + documentId);
  return urlPrefix + encodedDocumentId;
};
var isValidAutomergeUrl = (str) => {
  if (!str || !str.startsWith(urlPrefix))
    return false;
  const automergeUrl = str;
  try {
    const { documentId } = parseAutomergeUrl(automergeUrl);
    return isValidDocumentId(documentId);
  } catch {
    return false;
  }
};
var isValidDocumentId = (str) => {
  const binaryDocumentID = documentIdToBinary(str);
  if (binaryDocumentID === void 0)
    return false;
  const documentId = Uuid.stringify(binaryDocumentID);
  return Uuid.validate(documentId);
};
var isValidUuid = (str) => Uuid.validate(str);
var generateAutomergeUrl = () => {
  const documentId = Uuid.v4(null, new Uint8Array(16));
  return stringifyAutomergeUrl({ documentId });
};
var documentIdToBinary = (docId) => bs58check.decodeUnsafe(docId);
var binaryToDocumentId = (docId) => bs58check.encode(docId);
var interpretAsDocumentId = (id) => {
  if (id instanceof Uint8Array)
    return binaryToDocumentId(id);
  if (isValidAutomergeUrl(id))
    return parseAutomergeUrl(id).documentId;
  if (isValidDocumentId(id))
    return id;
  if (isValidUuid(id)) {
    console.warn("Future versions will not support UUIDs as document IDs; use Automerge URLs instead.");
    const binaryDocumentID = Uuid.parse(id);
    return binaryToDocumentId(binaryDocumentID);
  }
  throw new Error(`Invalid AutomergeUrl: '${id}'`);
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/cbor.js
var cbor_exports = {};
__export(cbor_exports, {
  decode: () => decode,
  encode: () => encode
});
import { Encoder, decode as cborXdecode } from "cbor-x";
function encode(obj) {
  const encoder = new Encoder({ tagUint8Array: false, useRecords: false });
  return encoder.encode(obj);
}
function decode(buf) {
  return cborXdecode(buf);
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/arraysAreEqual.js
var arraysAreEqual = (a, b) => a.length === b.length && a.every((element, index) => element === b[index]);

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/headsAreSame.js
var headsAreSame = (a, b) => {
  return arraysAreEqual(a, b);
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/withTimeout.js
var withTimeout = async (promise, t) => {
  let timeoutId;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => reject(new TimeoutError(`withTimeout: timed out after ${t}ms`)), t);
  });
  try {
    return await Promise.race([promise, timeoutPromise]);
  } finally {
    clearTimeout(timeoutId);
  }
};
var TimeoutError = class extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeoutError";
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/DocHandle.js
var DocHandle = class extends EventEmitter {
  documentId;
  #log;
  /** The XState actor running our state machine.  */
  #machine;
  /** The last known state of our document. */
  #prevDocState;
  /** How long to wait before giving up on a document. (Note that a document will be marked
   * unavailable much sooner if all known peers respond that they don't have it.) */
  #timeoutDelay = 6e4;
  /** A dictionary mapping each peer to the last heads we know they have. */
  #remoteHeads = {};
  /** @hidden */
  constructor(documentId, options = {}) {
    super();
    this.documentId = documentId;
    if ("timeoutDelay" in options && options.timeoutDelay) {
      this.#timeoutDelay = options.timeoutDelay;
    }
    let doc;
    const isNew = "isNew" in options && options.isNew;
    if (isNew) {
      doc = A.from(options.initialValue);
      doc = A.emptyChange(doc);
    } else {
      doc = A.init();
    }
    this.#log = debug(`automerge-repo:dochandle:${this.documentId.slice(0, 5)}`);
    const delay = this.#timeoutDelay;
    const machine = setup({
      types: {
        context: {},
        events: {}
      },
      actions: {
        /** Update the doc using the given callback and put the modified doc in context */
        onUpdate: assign(({ context, event }) => {
          const oldDoc = context.doc;
          assertEvent(event, UPDATE);
          const { callback } = event.payload;
          const doc2 = callback(oldDoc);
          return { doc: doc2 };
        }),
        onDelete: assign(() => {
          this.emit("delete", { handle: this });
          return { doc: void 0 };
        }),
        onUnavailable: () => {
          this.emit("unavailable", { handle: this });
        }
      }
    }).createMachine({
      /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgFUAFAEQEEAVAUQG0AGAXUVAAcB7WXAC64e+TiAAeiAOwAOAKwA6ACxSAzKqks1ATjlTdAGhABPRAFolAJksKN2y1KtKAbFLla5AX09G0WPISkVAwAMgyMrBxIILz8QiJikggAjCzOijKqLEqqybJyLizaRqYIFpbJtro5Uo7J2o5S3r4YOATECrgQADZgJADCAEoM9MzsYrGCwqLRSeoyCtra8pa5adquySXmDjY5ac7JljLJeepKzSB+bYGdPX0AYgCSAHJUkRN8UwmziM7HCgqyVcUnqcmScmcMm2ZV2yiyzkOx1OalUFx8V1aAQ63R46AgBCgJGGAEUyAwAMp0D7RSbxGagJKHFgKOSWJTJGRSCosCpKaEmRCqbQKU5yXINeTaer6LwY67YogKXH4wkkKgAeX6AH1hjQqABNGncL70xKIJQ5RY5BHOJag6wwpRyEWImQVeT1aWrVSXBXtJUqgn4Ik0ADqNCedG1L3CYY1gwA0saYqbpuaEG4pKLksKpFDgcsCjDhTnxTKpTLdH6sQGFOgAO7oKYhl5gAQNngAJwA1iRY3R40ndSNDSm6enfpm5BkWAVkvy7bpuTCKq7ndZnfVeSwuTX-HWu2AAI4AVzgQhD6q12rILxoADVIyEaAAhMLjtM-RmIE4LVSQi4nLLDIGzOCWwLKA0cgyLBoFWNy+43B0R5nheaqajqepjuMtJfgyEh-FoixqMCoKqOyhzgYKCDOq6UIeuCSxHOoSGKgop74OgABuzbdOgABGvTXlho5GrhJpxJOP4pLulT6KoMhpJY2hzsWNF0QobqMV6LG+pc+A8BAcBiP6gSfFJ36EQgKksksKxrHamwwmY7gLKB85QjBzoAWxdZdL0FnfARST8ooLC7qoTnWBU4pyC5ViVMKBQaHUDQuM4fm3EGhJBWaU7-CysEAUp3LpEpWw0WYRw2LmqzgqciIsCxWUdI2zaXlAbYdt2PZ5dJ1n5jY2iJY1ikOIcMJHCyUWHC62hRZkUVNPKta3Kh56wJ1-VWUyzhFc64JWJCtQNBBzhQW4cHwbsrVKpxPF8YJgV4ZZIWIKkiKiiNSkqZYWjzCWaQ5hFh0AcCuR3QoR74qUknBRmzholpv3OkpRQNNRpTzaKTWKbIWR5FDxm9AIkA7e9skUYCWayLILBZGoLkUSKbIyIdpxHPoyTeN4QA */
      // You can use the XState extension for VS Code to visualize this machine.
      // Or, you can see this static visualization (last updated April 2024): https://stately.ai/registry/editor/d7af9b58-c518-44f1-9c36-92a238b04a7a?machineId=91c387e7-0f01-42c9-a21d-293e9bf95bb7
      initial: "idle",
      context: { documentId, doc },
      on: {
        UPDATE: { actions: "onUpdate" },
        DELETE: ".deleted"
      },
      states: {
        idle: {
          on: {
            CREATE: "ready",
            FIND: "loading"
          }
        },
        loading: {
          on: {
            REQUEST: "requesting",
            DOC_READY: "ready",
            AWAIT_NETWORK: "awaitingNetwork"
          },
          after: { [delay]: "unavailable" }
        },
        awaitingNetwork: {
          on: { NETWORK_READY: "requesting" }
        },
        requesting: {
          on: {
            DOC_UNAVAILABLE: "unavailable",
            DOC_READY: "ready"
          },
          after: { [delay]: "unavailable" }
        },
        unavailable: {
          entry: "onUnavailable",
          on: { DOC_READY: "ready" }
        },
        ready: {},
        deleted: { entry: "onDelete", type: "final" }
      }
    });
    this.#machine = createActor(machine);
    this.#machine.subscribe((state) => {
      const before = this.#prevDocState;
      const after = state.context.doc;
      this.#log(`\u2192 ${state.value} %o`, after);
      this.#checkForChanges(before, after);
    });
    this.#machine.start();
    this.#machine.send(isNew ? { type: CREATE } : { type: FIND });
  }
  // PRIVATE
  /** Returns the current document, regardless of state */
  get #doc() {
    return this.#machine?.getSnapshot().context.doc;
  }
  /** Returns the docHandle's state (READY, etc.) */
  get #state() {
    return this.#machine?.getSnapshot().value;
  }
  /** Returns a promise that resolves when the docHandle is in one of the given states */
  #statePromise(awaitStates) {
    const awaitStatesArray = Array.isArray(awaitStates) ? awaitStates : [awaitStates];
    return waitFor(
      this.#machine,
      (s) => awaitStatesArray.some((state) => s.matches(state)),
      // use a longer delay here so as not to race with other delays
      { timeout: this.#timeoutDelay * 2 }
    );
  }
  /**
   * Called after state transitions. If the document has changed, emits a change event. If we just
   * received the document for the first time, signal that our request has been completed.
   */
  #checkForChanges(before, after) {
    const docChanged = after && before && !headsAreSame(A.getHeads(after), A.getHeads(before));
    if (docChanged) {
      this.emit("heads-changed", { handle: this, doc: after });
      const patches = A.diff(after, A.getHeads(before), A.getHeads(after));
      if (patches.length > 0) {
        this.emit("change", {
          handle: this,
          doc: after,
          patches,
          // TODO: pass along the source (load/change/network)
          patchInfo: { before, after, source: "change" }
        });
      }
      if (!this.isReady())
        this.#machine.send({ type: DOC_READY });
    }
    this.#prevDocState = after;
  }
  // PUBLIC
  /** Our documentId in Automerge URL form.
   */
  get url() {
    return stringifyAutomergeUrl({ documentId: this.documentId });
  }
  /**
   * @returns true if the document is ready for accessing or changes.
   *
   * Note that for documents already stored locally this occurs before synchronization with any
   * peers. We do not currently have an equivalent `whenSynced()`.
   */
  isReady = () => this.inState(["ready"]);
  /**
   * @returns true if the document has been marked as deleted.
   *
   * Deleted documents are removed from local storage and the sync process. It's not currently
   * possible at runtime to undelete a document.
   */
  isDeleted = () => this.inState(["deleted"]);
  /**
   * @returns true if the document is currently unavailable.
   *
   * This will be the case if the document is not found in storage and no peers have shared it with us.
   */
  isUnavailable = () => this.inState(["unavailable"]);
  /**
   * @returns true if the handle is in one of the given states.
   */
  inState = (states) => states.some((s) => this.#machine.getSnapshot().matches(s));
  /** @hidden */
  get state() {
    return this.#machine.getSnapshot().value;
  }
  /**
   * @returns a promise that resolves when the document is in one of the given states (if no states
   * are passed, when the document is ready)
   *
   * Use this to block until the document handle has finished loading. The async equivalent to
   * checking `inState()`.
   */
  async whenReady(awaitStates = ["ready"]) {
    await withTimeout(this.#statePromise(awaitStates), this.#timeoutDelay);
  }
  /**
   * @returns the current state of this handle's Automerge document.
   *
   * This is the recommended way to access a handle's document. Note that this waits for the handle
   * to be ready if necessary. If loading (or synchronization) fails, this will never resolve.
   */
  async doc(awaitStates = ["ready", "unavailable"]) {
    try {
      await this.#statePromise(awaitStates);
    } catch (error) {
      return void 0;
    }
    return !this.isUnavailable() ? this.#doc : void 0;
  }
  /**
   * Synchronously returns the current state of the Automerge document this handle manages, or
   * undefined. Consider using `await handle.doc()` instead. Check `isReady()`, or use `whenReady()`
   * if you want to make sure loading is complete first.
   *
   * Not to be confused with the SyncState of the document, which describes the state of the
   * synchronization process.
   *
   * Note that `undefined` is not a valid Automerge document, so the return from this function is
   * unambigous.
   *
   * @returns the current document, or undefined if the document is not ready.
   */
  docSync() {
    if (!this.isReady())
      return void 0;
    else
      return this.#doc;
  }
  /**
   * Returns the current "heads" of the document, akin to a git commit.
   * This precisely defines the state of a document.
   * @returns the current document's heads, or undefined if the document is not ready
   */
  heads() {
    if (!this.isReady()) {
      return void 0;
    }
    return A.getHeads(this.#doc);
  }
  /**
   * `update` is called by the repo when we receive changes from the network
   * Called by the repo when we receive changes from the network.
   * @hidden
   */
  update(callback) {
    this.#machine.send({ type: UPDATE, payload: { callback } });
  }
  /**
   * Called by the repo either when a doc handle changes or we receive new remote heads.
   * @hidden
   */
  setRemoteHeads(storageId, heads) {
    this.#remoteHeads[storageId] = heads;
    this.emit("remote-heads", { storageId, heads });
  }
  /** Returns the heads of the storageId. */
  getRemoteHeads(storageId) {
    return this.#remoteHeads[storageId];
  }
  /**
   * All changes to an Automerge document should be made through this method.
   * Inside the callback, the document should be treated as mutable: all edits will be recorded
   * using a Proxy and translated into operations as part of a single recorded "change".
   *
   * Note that assignment via ES6 spread operators will result in *replacing* the object
   * instead of mutating it which will prevent clean merges. This may be what you want, but
   * `doc.foo = { ...doc.foo, bar: "baz" }` is not equivalent to `doc.foo.bar = "baz"`.
   *
   * Local changes will be stored (by the StorageSubsystem) and synchronized (by the
   * DocSynchronizer) to any peers you are sharing it with.
   *
   * @param callback - A function that takes the current document and mutates it.
   *
   */
  change(callback, options = {}) {
    if (!this.isReady()) {
      throw new Error(`DocHandle#${this.documentId} is not ready. Check \`handle.isReady()\` before accessing the document.`);
    }
    this.#machine.send({
      type: UPDATE,
      payload: { callback: (doc) => A.change(doc, options, callback) }
    });
  }
  /**
   * Makes a change as if the document were at `heads`.
   *
   * @returns A set of heads representing the concurrent change that was made.
   */
  changeAt(heads, callback, options = {}) {
    if (!this.isReady()) {
      throw new Error(`DocHandle#${this.documentId} is not ready. Check \`handle.isReady()\` before accessing the document.`);
    }
    let resultHeads = void 0;
    this.#machine.send({
      type: UPDATE,
      payload: {
        callback: (doc) => {
          const result = A.changeAt(doc, heads, options, callback);
          resultHeads = result.newHeads || void 0;
          return result.newDoc;
        }
      }
    });
    return resultHeads;
  }
  /**
   * Merges another document into this document. Any peers we are sharing changes with will be
   * notified of the changes resulting from the merge.
   *
   * @returns the merged document.
   *
   * @throws if either document is not ready or if `otherHandle` is unavailable.
   */
  merge(otherHandle) {
    if (!this.isReady() || !otherHandle.isReady()) {
      throw new Error("Both handles must be ready to merge");
    }
    const mergingDoc = otherHandle.docSync();
    if (!mergingDoc) {
      throw new Error("The document to be merged in is falsy, aborting.");
    }
    this.update((doc) => {
      return A.merge(doc, mergingDoc);
    });
  }
  /**
   * Used in testing to mark this document as unavailable.
   * @hidden
   */
  unavailable() {
    this.#machine.send({ type: DOC_UNAVAILABLE });
  }
  /** Called by the repo when the document is not found in storage.
   * @hidden
   * */
  request() {
    if (this.#state === "loading")
      this.#machine.send({ type: REQUEST });
  }
  /** @hidden */
  awaitNetwork() {
    if (this.#state === "loading")
      this.#machine.send({ type: AWAIT_NETWORK });
  }
  /** @hidden */
  networkReady() {
    if (this.#state === "awaitingNetwork")
      this.#machine.send({ type: NETWORK_READY });
  }
  /** Called by the repo when the document is deleted. */
  delete() {
    this.#machine.send({ type: DELETE });
  }
  /**
   * Sends an arbitrary ephemeral message out to all reachable peers who would receive sync messages
   * from you. It has no guarantee of delivery, and is not persisted to the underlying automerge doc
   * in any way. Messages will have a sending PeerId but this is *not* a useful user identifier (a
   * user could have multiple tabs open and would appear as multiple PeerIds). Every message source
   * must have a unique PeerId.
   */
  broadcast(message) {
    this.emit("ephemeral-message-outbound", {
      handle: this,
      data: encode(message)
    });
  }
};
var HandleState = {
  /** The handle has been created but not yet loaded or requested */
  IDLE: "idle",
  /** We are waiting for storage to finish loading */
  LOADING: "loading",
  /** We are waiting for the network to be come ready */
  AWAITING_NETWORK: "awaitingNetwork",
  /** We are waiting for someone in the network to respond to a sync request */
  REQUESTING: "requesting",
  /** The document is available */
  READY: "ready",
  /** The document has been deleted from the repo */
  DELETED: "deleted",
  /** The document was not available in storage or from any connected peers */
  UNAVAILABLE: "unavailable"
};
var { IDLE, LOADING, AWAITING_NETWORK, REQUESTING, READY, DELETED, UNAVAILABLE } = HandleState;
var CREATE = "CREATE";
var FIND = "FIND";
var REQUEST = "REQUEST";
var DOC_READY = "DOC_READY";
var AWAIT_NETWORK = "AWAIT_NETWORK";
var NETWORK_READY = "NETWORK_READY";
var UPDATE = "UPDATE";
var DELETE = "DELETE";
var DOC_UNAVAILABLE = "DOC_UNAVAILABLE";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/Repo.js
import { next as Automerge } from "#automerge";
import debug7 from "debug";
import { EventEmitter as EventEmitter5 } from "eventemitter3";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/RemoteHeadsSubscriptions.js
import { EventEmitter as EventEmitter2 } from "eventemitter3";
import debug2 from "debug";
var RemoteHeadsSubscriptions = class extends EventEmitter2 {
  // Storage IDs we have received remote heads from
  #knownHeads = /* @__PURE__ */ new Map();
  // Storage IDs we have subscribed to via Repo.subscribeToRemoteHeads
  #ourSubscriptions = /* @__PURE__ */ new Set();
  // Storage IDs other peers have subscribed to by sending us a control message
  #theirSubscriptions = /* @__PURE__ */ new Map();
  // Peers we will always share remote heads with even if they are not subscribed
  #generousPeers = /* @__PURE__ */ new Set();
  // Documents each peer has open, we need this information so we only send remote heads of documents that the peer knows
  #subscribedDocsByPeer = /* @__PURE__ */ new Map();
  #log = debug2("automerge-repo:remote-heads-subscriptions");
  subscribeToRemotes(remotes) {
    this.#log("subscribeToRemotes", remotes);
    const remotesToAdd = [];
    for (const remote of remotes) {
      if (!this.#ourSubscriptions.has(remote)) {
        this.#ourSubscriptions.add(remote);
        remotesToAdd.push(remote);
      }
    }
    if (remotesToAdd.length > 0) {
      this.emit("change-remote-subs", {
        add: remotesToAdd,
        peers: Array.from(this.#generousPeers)
      });
    }
  }
  unsubscribeFromRemotes(remotes) {
    this.#log("subscribeToRemotes", remotes);
    const remotesToRemove = [];
    for (const remote of remotes) {
      if (this.#ourSubscriptions.has(remote)) {
        this.#ourSubscriptions.delete(remote);
        if (!this.#theirSubscriptions.has(remote)) {
          remotesToRemove.push(remote);
        }
      }
    }
    if (remotesToRemove.length > 0) {
      this.emit("change-remote-subs", {
        remove: remotesToRemove,
        peers: Array.from(this.#generousPeers)
      });
    }
  }
  handleControlMessage(control) {
    const remotesToAdd = [];
    const remotesToRemove = [];
    const addedRemotesWeKnow = [];
    this.#log("handleControlMessage", control);
    if (control.add) {
      for (const remote of control.add) {
        let theirSubs = this.#theirSubscriptions.get(remote);
        if (this.#ourSubscriptions.has(remote) || theirSubs) {
          addedRemotesWeKnow.push(remote);
        }
        if (!theirSubs) {
          theirSubs = /* @__PURE__ */ new Set();
          this.#theirSubscriptions.set(remote, theirSubs);
          if (!this.#ourSubscriptions.has(remote)) {
            remotesToAdd.push(remote);
          }
        }
        theirSubs.add(control.senderId);
      }
    }
    if (control.remove) {
      for (const remote of control.remove) {
        const theirSubs = this.#theirSubscriptions.get(remote);
        if (theirSubs) {
          theirSubs.delete(control.senderId);
          if (theirSubs.size == 0 && !this.#ourSubscriptions.has(remote)) {
            remotesToRemove.push(remote);
          }
        }
      }
    }
    if (remotesToAdd.length > 0 || remotesToRemove.length > 0) {
      this.emit("change-remote-subs", {
        peers: Array.from(this.#generousPeers),
        add: remotesToAdd,
        remove: remotesToRemove
      });
    }
    for (const remote of addedRemotesWeKnow) {
      const subscribedDocs = this.#subscribedDocsByPeer.get(control.senderId);
      if (subscribedDocs) {
        for (const documentId of subscribedDocs) {
          const knownHeads = this.#knownHeads.get(documentId);
          if (!knownHeads) {
            continue;
          }
          const lastHeads = knownHeads.get(remote);
          if (lastHeads) {
            this.emit("notify-remote-heads", {
              targetId: control.senderId,
              documentId,
              heads: lastHeads.heads,
              timestamp: lastHeads.timestamp,
              storageId: remote
            });
          }
        }
      }
    }
  }
  /** A peer we are not directly connected to has changed their heads */
  handleRemoteHeads(msg) {
    this.#log("handleRemoteHeads", msg);
    const changedHeads = this.#changedHeads(msg);
    for (const event of changedHeads) {
      if (this.#ourSubscriptions.has(event.storageId)) {
        this.emit("remote-heads-changed", event);
      }
    }
    for (const event of changedHeads) {
      for (const peer of this.#generousPeers) {
        if (peer === msg.senderId) {
          continue;
        }
        this.emit("notify-remote-heads", {
          targetId: peer,
          documentId: event.documentId,
          heads: event.remoteHeads,
          timestamp: event.timestamp,
          storageId: event.storageId
        });
      }
    }
    for (const event of changedHeads) {
      const theirSubs = this.#theirSubscriptions.get(event.storageId);
      if (theirSubs) {
        for (const peerId of theirSubs) {
          if (this.#isPeerSubscribedToDoc(peerId, event.documentId)) {
            this.emit("notify-remote-heads", {
              targetId: peerId,
              documentId: event.documentId,
              heads: event.remoteHeads,
              timestamp: event.timestamp,
              storageId: event.storageId
            });
          }
        }
      }
    }
  }
  /** A peer we are directly connected to has updated their heads */
  handleImmediateRemoteHeadsChanged(documentId, storageId, heads) {
    this.#log("handleLocalHeadsChanged", documentId, storageId, heads);
    const remote = this.#knownHeads.get(documentId);
    const timestamp = Date.now();
    if (!remote) {
      this.#knownHeads.set(documentId, /* @__PURE__ */ new Map([[storageId, { heads, timestamp }]]));
    } else {
      const docRemote = remote.get(storageId);
      if (!docRemote || docRemote.timestamp < Date.now()) {
        remote.set(storageId, { heads, timestamp: Date.now() });
      }
    }
    const theirSubs = this.#theirSubscriptions.get(storageId);
    if (theirSubs) {
      for (const peerId of theirSubs) {
        if (this.#isPeerSubscribedToDoc(peerId, documentId)) {
          this.emit("notify-remote-heads", {
            targetId: peerId,
            documentId,
            heads,
            timestamp,
            storageId
          });
        }
      }
    }
  }
  addGenerousPeer(peerId) {
    this.#log("addGenerousPeer", peerId);
    this.#generousPeers.add(peerId);
    if (this.#ourSubscriptions.size > 0) {
      this.emit("change-remote-subs", {
        add: Array.from(this.#ourSubscriptions),
        peers: [peerId]
      });
    }
    for (const [documentId, remote] of this.#knownHeads) {
      for (const [storageId, { heads, timestamp }] of remote) {
        this.emit("notify-remote-heads", {
          targetId: peerId,
          documentId,
          heads,
          timestamp,
          storageId
        });
      }
    }
  }
  removePeer(peerId) {
    this.#log("removePeer", peerId);
    const remotesToRemove = [];
    this.#generousPeers.delete(peerId);
    this.#subscribedDocsByPeer.delete(peerId);
    for (const [storageId, peerIds] of this.#theirSubscriptions) {
      if (peerIds.has(peerId)) {
        peerIds.delete(peerId);
        if (peerIds.size == 0) {
          remotesToRemove.push(storageId);
          this.#theirSubscriptions.delete(storageId);
        }
      }
    }
    if (remotesToRemove.length > 0) {
      this.emit("change-remote-subs", {
        remove: remotesToRemove,
        peers: Array.from(this.#generousPeers)
      });
    }
  }
  subscribePeerToDoc(peerId, documentId) {
    let subscribedDocs = this.#subscribedDocsByPeer.get(peerId);
    if (!subscribedDocs) {
      subscribedDocs = /* @__PURE__ */ new Set();
      this.#subscribedDocsByPeer.set(peerId, subscribedDocs);
    }
    subscribedDocs.add(documentId);
    const remoteHeads = this.#knownHeads.get(documentId);
    if (remoteHeads) {
      for (const [storageId, lastHeads] of remoteHeads) {
        const subscribedPeers = this.#theirSubscriptions.get(storageId);
        if (subscribedPeers && subscribedPeers.has(peerId)) {
          this.emit("notify-remote-heads", {
            targetId: peerId,
            documentId,
            heads: lastHeads.heads,
            timestamp: lastHeads.timestamp,
            storageId
          });
        }
      }
    }
  }
  #isPeerSubscribedToDoc(peerId, documentId) {
    const subscribedDocs = this.#subscribedDocsByPeer.get(peerId);
    return subscribedDocs && subscribedDocs.has(documentId);
  }
  /** Returns the (document, storageId) pairs which have changed after processing msg */
  #changedHeads(msg) {
    const changedHeads = [];
    const { documentId, newHeads } = msg;
    for (const [storageId, { heads, timestamp }] of Object.entries(newHeads)) {
      if (!this.#ourSubscriptions.has(storageId) && !this.#theirSubscriptions.has(storageId)) {
        continue;
      }
      let remote = this.#knownHeads.get(documentId);
      if (!remote) {
        remote = /* @__PURE__ */ new Map();
        this.#knownHeads.set(documentId, remote);
      }
      const docRemote = remote.get(storageId);
      if (docRemote && docRemote.timestamp >= timestamp) {
        continue;
      } else {
        remote.set(storageId, { timestamp, heads });
        changedHeads.push({
          documentId,
          storageId,
          remoteHeads: heads,
          timestamp
        });
      }
    }
    return changedHeads;
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/throttle.js
var throttle = (fn, delay) => {
  let lastCall = Date.now();
  let wait;
  let timeout;
  return function(...args) {
    wait = lastCall + delay - Date.now();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
      lastCall = Date.now();
    }, wait);
  };
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/network/NetworkSubsystem.js
import debug3 from "debug";
import { EventEmitter as EventEmitter3 } from "eventemitter3";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/network/messages.js
var isRepoMessage = (message) => isSyncMessage(message) || isEphemeralMessage(message) || isRequestMessage(message) || isDocumentUnavailableMessage(message) || isRemoteSubscriptionControlMessage(message) || isRemoteHeadsChanged(message);
var isDocumentUnavailableMessage = (msg) => msg.type === "doc-unavailable";
var isRequestMessage = (msg) => msg.type === "request";
var isSyncMessage = (msg) => msg.type === "sync";
var isEphemeralMessage = (msg) => msg.type === "ephemeral";
var isRemoteSubscriptionControlMessage = (msg) => msg.type === "remote-subscription-change";
var isRemoteHeadsChanged = (msg) => msg.type === "remote-heads-changed";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/network/NetworkSubsystem.js
var getEphemeralMessageSource = (message) => `${message.senderId}:${message.sessionId}`;
var NetworkSubsystem = class extends EventEmitter3 {
  peerId;
  peerMetadata;
  #log;
  #adaptersByPeer = {};
  #count = 0;
  #sessionId = Math.random().toString(36).slice(2);
  #ephemeralSessionCounts = {};
  #readyAdapterCount = 0;
  #adapters = [];
  constructor(adapters, peerId = randomPeerId(), peerMetadata) {
    super();
    this.peerId = peerId;
    this.peerMetadata = peerMetadata;
    this.#log = debug3(`automerge-repo:network:${this.peerId}`);
    adapters.forEach((a) => this.addNetworkAdapter(a));
  }
  addNetworkAdapter(networkAdapter) {
    this.#adapters.push(networkAdapter);
    networkAdapter.once("ready", () => {
      this.#readyAdapterCount++;
      this.#log("Adapters ready: ", this.#readyAdapterCount, "/", this.#adapters.length);
      if (this.#readyAdapterCount === this.#adapters.length) {
        this.emit("ready");
      }
    });
    networkAdapter.on("peer-candidate", ({ peerId, peerMetadata }) => {
      this.#log(`peer candidate: ${peerId} `);
      if (!this.#adaptersByPeer[peerId]) {
        this.#adaptersByPeer[peerId] = networkAdapter;
      }
      this.emit("peer", { peerId, peerMetadata });
    });
    networkAdapter.on("peer-disconnected", ({ peerId }) => {
      this.#log(`peer disconnected: ${peerId} `);
      delete this.#adaptersByPeer[peerId];
      this.emit("peer-disconnected", { peerId });
    });
    networkAdapter.on("message", (msg) => {
      if (!isRepoMessage(msg)) {
        this.#log(`invalid message: ${JSON.stringify(msg)}`);
        return;
      }
      this.#log(`message from ${msg.senderId}`);
      if (isEphemeralMessage(msg)) {
        const source = getEphemeralMessageSource(msg);
        if (this.#ephemeralSessionCounts[source] === void 0 || msg.count > this.#ephemeralSessionCounts[source]) {
          this.#ephemeralSessionCounts[source] = msg.count;
          this.emit("message", msg);
        }
        return;
      }
      this.emit("message", msg);
    });
    networkAdapter.on("close", () => {
      this.#log("adapter closed");
      Object.entries(this.#adaptersByPeer).forEach(([peerId, other]) => {
        if (other === networkAdapter) {
          delete this.#adaptersByPeer[peerId];
        }
      });
    });
    this.peerMetadata.then((peerMetadata) => {
      networkAdapter.connect(this.peerId, peerMetadata);
    }).catch((err) => {
      this.#log("error connecting to network", err);
    });
  }
  send(message) {
    const peer = this.#adaptersByPeer[message.targetId];
    if (!peer) {
      this.#log(`Tried to send message but peer not found: ${message.targetId}`);
      return;
    }
    const prepareMessage = (message2) => {
      if (message2.type === "ephemeral") {
        if ("count" in message2) {
          return message2;
        } else {
          return {
            ...message2,
            count: ++this.#count,
            sessionId: this.#sessionId,
            senderId: this.peerId
          };
        }
      } else {
        return {
          ...message2,
          senderId: this.peerId
        };
      }
    };
    const outbound = prepareMessage(message);
    this.#log("sending message %o", outbound);
    peer.send(outbound);
  }
  isReady = () => {
    return this.#readyAdapterCount === this.#adapters.length;
  };
  whenReady = async () => {
    if (this.isReady()) {
      return;
    } else {
      return new Promise((resolve) => {
        this.once("ready", () => {
          resolve();
        });
      });
    }
  };
};
function randomPeerId() {
  return `user-${Math.round(Math.random() * 1e5)}`;
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/storage/StorageSubsystem.js
import * as A2 from "#automerge/next";
import debug4 from "debug";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/helpers/mergeArrays.js
function mergeArrays(myArrays) {
  let length = 0;
  myArrays.forEach((item) => {
    length += item.length;
  });
  const mergedArray = new Uint8Array(length);
  let offset = 0;
  myArrays.forEach((item) => {
    mergedArray.set(item, offset);
    offset += item.length;
  });
  return mergedArray;
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/storage/keyHash.js
import * as sha256 from "fast-sha256";
function keyHash(binary) {
  const hash2 = sha256.hash(binary);
  return bufferToHexString(hash2);
}
function headsHash(heads) {
  const encoder = new TextEncoder();
  const headsbinary = mergeArrays(heads.map((h) => encoder.encode(h)));
  return keyHash(headsbinary);
}
function bufferToHexString(data) {
  return Array.from(data, (byte) => byte.toString(16).padStart(2, "0")).join("");
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/storage/chunkTypeFromKey.js
function chunkTypeFromKey(key) {
  if (key.length < 2)
    return null;
  const chunkTypeStr = key[key.length - 2];
  if (chunkTypeStr === "snapshot" || chunkTypeStr === "incremental") {
    return chunkTypeStr;
  }
  return null;
}

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/storage/StorageSubsystem.js
import * as Uuid2 from "uuid";
var StorageSubsystem = class {
  /** The storage adapter to use for saving and loading documents */
  #storageAdapter;
  /** Record of the latest heads we've loaded or saved for each document  */
  #storedHeads = /* @__PURE__ */ new Map();
  /** Metadata on the chunks we've already loaded for each document */
  #chunkInfos = /* @__PURE__ */ new Map();
  /** Flag to avoid compacting when a compaction is already underway */
  #compacting = false;
  #log = debug4(`automerge-repo:storage-subsystem`);
  constructor(storageAdapter) {
    this.#storageAdapter = storageAdapter;
  }
  async id() {
    const storedId = await this.#storageAdapter.load(["storage-adapter-id"]);
    let id;
    if (storedId) {
      id = new TextDecoder().decode(storedId);
    } else {
      id = Uuid2.v4();
      await this.#storageAdapter.save(["storage-adapter-id"], new TextEncoder().encode(id));
    }
    return id;
  }
  // ARBITRARY KEY/VALUE STORAGE
  // The `load`, `save`, and `remove` methods are for generic key/value storage, as opposed to
  // Automerge documents. For example, they're used by the LocalFirstAuthProvider to persist the
  // encrypted team graph that encodes group membership and permissions.
  //
  // The namespace parameter is to prevent collisions with other users of the storage subsystem.
  // Typically this will be the name of the plug-in, adapter, or other system that is using it. For
  // example, the LocalFirstAuthProvider uses the namespace `LocalFirstAuthProvider`.
  /** Loads a value from storage. */
  async load(namespace, key) {
    const storageKey = [namespace, key];
    return await this.#storageAdapter.load(storageKey);
  }
  /** Saves a value in storage. */
  async save(namespace, key, data) {
    const storageKey = [namespace, key];
    await this.#storageAdapter.save(storageKey, data);
  }
  /** Removes a value from storage. */
  async remove(namespace, key) {
    const storageKey = [namespace, key];
    await this.#storageAdapter.remove(storageKey);
  }
  // AUTOMERGE DOCUMENT STORAGE
  /**
   * Loads the Automerge document with the given ID from storage.
   */
  async loadDoc(documentId) {
    const chunks = await this.#storageAdapter.loadRange([documentId]);
    const binaries = [];
    const chunkInfos = [];
    for (const chunk of chunks) {
      if (chunk.data === void 0)
        continue;
      const chunkType = chunkTypeFromKey(chunk.key);
      if (chunkType == null)
        continue;
      chunkInfos.push({
        key: chunk.key,
        type: chunkType,
        size: chunk.data.length
      });
      binaries.push(chunk.data);
    }
    this.#chunkInfos.set(documentId, chunkInfos);
    const binary = mergeArrays(binaries);
    if (binary.length === 0)
      return null;
    const newDoc = A2.loadIncremental(A2.init(), binary);
    this.#storedHeads.set(documentId, A2.getHeads(newDoc));
    return newDoc;
  }
  /**
   * Saves the provided Automerge document to storage.
   *
   * @remarks
   * Under the hood this makes incremental saves until the incremental size is greater than the
   * snapshot size, at which point the document is compacted into a single snapshot.
   */
  async saveDoc(documentId, doc) {
    if (!this.#shouldSave(documentId, doc))
      return;
    const sourceChunks = this.#chunkInfos.get(documentId) ?? [];
    if (this.#shouldCompact(sourceChunks)) {
      await this.#saveTotal(documentId, doc, sourceChunks);
    } else {
      await this.#saveIncremental(documentId, doc);
    }
    this.#storedHeads.set(documentId, A2.getHeads(doc));
  }
  /**
   * Removes the Automerge document with the given ID from storage
   */
  async removeDoc(documentId) {
    await this.#storageAdapter.removeRange([documentId, "snapshot"]);
    await this.#storageAdapter.removeRange([documentId, "incremental"]);
    await this.#storageAdapter.removeRange([documentId, "sync-state"]);
  }
  /**
   * Saves just the incremental changes since the last save.
   */
  async #saveIncremental(documentId, doc) {
    const binary = A2.saveSince(doc, this.#storedHeads.get(documentId) ?? []);
    if (binary && binary.length > 0) {
      const key = [documentId, "incremental", keyHash(binary)];
      this.#log(`Saving incremental ${key} for document ${documentId}`);
      await this.#storageAdapter.save(key, binary);
      if (!this.#chunkInfos.has(documentId)) {
        this.#chunkInfos.set(documentId, []);
      }
      this.#chunkInfos.get(documentId).push({
        key,
        type: "incremental",
        size: binary.length
      });
      this.#storedHeads.set(documentId, A2.getHeads(doc));
    } else {
      return Promise.resolve();
    }
  }
  /**
   * Compacts the document storage into a single shapshot.
   */
  async #saveTotal(documentId, doc, sourceChunks) {
    this.#compacting = true;
    const binary = A2.save(doc);
    const snapshotHash = headsHash(A2.getHeads(doc));
    const key = [documentId, "snapshot", snapshotHash];
    const oldKeys = new Set(sourceChunks.map((c) => c.key).filter((k) => k[2] !== snapshotHash));
    this.#log(`Saving snapshot ${key} for document ${documentId}`);
    this.#log(`deleting old chunks ${Array.from(oldKeys)}`);
    await this.#storageAdapter.save(key, binary);
    for (const key2 of oldKeys) {
      await this.#storageAdapter.remove(key2);
    }
    const newChunkInfos = this.#chunkInfos.get(documentId)?.filter((c) => !oldKeys.has(c.key)) ?? [];
    newChunkInfos.push({ key, type: "snapshot", size: binary.length });
    this.#chunkInfos.set(documentId, newChunkInfos);
    this.#compacting = false;
  }
  async loadSyncState(documentId, storageId) {
    const key = [documentId, "sync-state", storageId];
    const loaded = await this.#storageAdapter.load(key);
    return loaded ? A2.decodeSyncState(loaded) : void 0;
  }
  async saveSyncState(documentId, storageId, syncState) {
    const key = [documentId, "sync-state", storageId];
    await this.#storageAdapter.save(key, A2.encodeSyncState(syncState));
  }
  /**
   * Returns true if the document has changed since the last time it was saved.
   */
  #shouldSave(documentId, doc) {
    const oldHeads = this.#storedHeads.get(documentId);
    if (!oldHeads) {
      return true;
    }
    const newHeads = A2.getHeads(doc);
    if (headsAreSame(newHeads, oldHeads)) {
      return false;
    }
    return true;
  }
  /**
   * We only compact if the incremental size is greater than the snapshot size.
   */
  #shouldCompact(sourceChunks) {
    if (this.#compacting)
      return false;
    let snapshotSize = 0;
    let incrementalSize = 0;
    for (const chunk of sourceChunks) {
      if (chunk.type === "snapshot") {
        snapshotSize += chunk.size;
      } else {
        incrementalSize += chunk.size;
      }
    }
    return snapshotSize < 1024 || incrementalSize >= snapshotSize;
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/synchronizer/CollectionSynchronizer.js
import debug6 from "debug";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/synchronizer/DocSynchronizer.js
import * as A3 from "#automerge/next";
import { decode as decode2 } from "cbor-x";
import debug5 from "debug";

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/synchronizer/Synchronizer.js
import { EventEmitter as EventEmitter4 } from "eventemitter3";
var Synchronizer = class extends EventEmitter4 {
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/synchronizer/DocSynchronizer.js
var DocSynchronizer = class extends Synchronizer {
  #log;
  syncDebounceRate = 100;
  /** Active peers */
  #peers = [];
  #pendingSyncStateCallbacks = {};
  #peerDocumentStatuses = {};
  /** Sync state for each peer we've communicated with (including inactive peers) */
  #syncStates = {};
  #pendingSyncMessages = [];
  #syncStarted = false;
  #handle;
  #onLoadSyncState;
  constructor({ handle, onLoadSyncState }) {
    super();
    this.#handle = handle;
    this.#onLoadSyncState = onLoadSyncState ?? (() => Promise.resolve(void 0));
    const docId = handle.documentId.slice(0, 5);
    this.#log = debug5(`automerge-repo:docsync:${docId}`);
    handle.on("change", throttle(() => this.#syncWithPeers(), this.syncDebounceRate));
    handle.on("ephemeral-message-outbound", (payload) => this.#broadcastToPeers(payload));
    void (async () => {
      await handle.doc([READY, REQUESTING]);
      this.#processAllPendingSyncMessages();
    })();
  }
  get peerStates() {
    return this.#peerDocumentStatuses;
  }
  get documentId() {
    return this.#handle.documentId;
  }
  /// PRIVATE
  async #syncWithPeers() {
    this.#log(`syncWithPeers`);
    const doc = await this.#handle.doc();
    if (doc === void 0)
      return;
    this.#peers.forEach((peerId) => this.#sendSyncMessage(peerId, doc));
  }
  async #broadcastToPeers({ data }) {
    this.#log(`broadcastToPeers`, this.#peers);
    this.#peers.forEach((peerId) => this.#sendEphemeralMessage(peerId, data));
  }
  #sendEphemeralMessage(peerId, data) {
    this.#log(`sendEphemeralMessage ->${peerId}`);
    const message = {
      type: "ephemeral",
      targetId: peerId,
      documentId: this.#handle.documentId,
      data
    };
    this.emit("message", message);
  }
  #withSyncState(peerId, callback) {
    this.#addPeer(peerId);
    if (!(peerId in this.#peerDocumentStatuses)) {
      this.#peerDocumentStatuses[peerId] = "unknown";
    }
    const syncState = this.#syncStates[peerId];
    if (syncState) {
      callback(syncState);
      return;
    }
    let pendingCallbacks = this.#pendingSyncStateCallbacks[peerId];
    if (!pendingCallbacks) {
      this.#onLoadSyncState(peerId).then((syncState2) => {
        this.#initSyncState(peerId, syncState2 ?? A3.initSyncState());
      }).catch((err) => {
        this.#log(`Error loading sync state for ${peerId}: ${err}`);
      });
      pendingCallbacks = this.#pendingSyncStateCallbacks[peerId] = [];
    }
    pendingCallbacks.push(callback);
  }
  #addPeer(peerId) {
    if (!this.#peers.includes(peerId)) {
      this.#peers.push(peerId);
      this.emit("open-doc", { documentId: this.documentId, peerId });
    }
  }
  #initSyncState(peerId, syncState) {
    const pendingCallbacks = this.#pendingSyncStateCallbacks[peerId];
    if (pendingCallbacks) {
      for (const callback of pendingCallbacks) {
        callback(syncState);
      }
    }
    delete this.#pendingSyncStateCallbacks[peerId];
    this.#syncStates[peerId] = syncState;
  }
  #setSyncState(peerId, syncState) {
    this.#syncStates[peerId] = syncState;
    this.emit("sync-state", {
      peerId,
      syncState,
      documentId: this.#handle.documentId
    });
  }
  #sendSyncMessage(peerId, doc) {
    this.#log(`sendSyncMessage ->${peerId}`);
    this.#withSyncState(peerId, (syncState) => {
      const [newSyncState, message] = A3.generateSyncMessage(doc, syncState);
      if (message) {
        this.#setSyncState(peerId, newSyncState);
        const isNew = A3.getHeads(doc).length === 0;
        if (!this.#handle.isReady() && isNew && newSyncState.sharedHeads.length === 0 && !Object.values(this.#peerDocumentStatuses).includes("has") && this.#peerDocumentStatuses[peerId] === "unknown") {
          this.emit("message", {
            type: "request",
            targetId: peerId,
            documentId: this.#handle.documentId,
            data: message
          });
        } else {
          this.emit("message", {
            type: "sync",
            targetId: peerId,
            data: message,
            documentId: this.#handle.documentId
          });
        }
        if (!isNew) {
          this.#peerDocumentStatuses[peerId] = "has";
        }
      }
    });
  }
  /// PUBLIC
  hasPeer(peerId) {
    return this.#peers.includes(peerId);
  }
  beginSync(peerIds) {
    const noPeersWithDocument = peerIds.every((peerId) => this.#peerDocumentStatuses[peerId] in ["unavailable", "wants"]);
    const docPromise = this.#handle.doc([READY, REQUESTING, UNAVAILABLE]).then((doc) => {
      this.#syncStarted = true;
      this.#checkDocUnavailable();
      const wasUnavailable = doc === void 0;
      if (wasUnavailable && noPeersWithDocument) {
        return;
      }
      return doc ?? A3.init();
    });
    this.#log(`beginSync: ${peerIds.join(", ")}`);
    peerIds.forEach((peerId) => {
      this.#withSyncState(peerId, (syncState) => {
        const reparsedSyncState = A3.decodeSyncState(A3.encodeSyncState(syncState));
        this.#setSyncState(peerId, reparsedSyncState);
        docPromise.then((doc) => {
          if (doc) {
            this.#sendSyncMessage(peerId, doc);
          }
        }).catch((err) => {
          this.#log(`Error loading doc for ${peerId}: ${err}`);
        });
      });
    });
  }
  endSync(peerId) {
    this.#log(`removing peer ${peerId}`);
    this.#peers = this.#peers.filter((p) => p !== peerId);
  }
  receiveMessage(message) {
    switch (message.type) {
      case "sync":
      case "request":
        this.receiveSyncMessage(message);
        break;
      case "ephemeral":
        this.receiveEphemeralMessage(message);
        break;
      case "doc-unavailable":
        this.#peerDocumentStatuses[message.senderId] = "unavailable";
        this.#checkDocUnavailable();
        break;
      default:
        throw new Error(`unknown message type: ${message}`);
    }
  }
  receiveEphemeralMessage(message) {
    if (message.documentId !== this.#handle.documentId)
      throw new Error(`channelId doesn't match documentId`);
    const { senderId, data } = message;
    const contents = decode2(new Uint8Array(data));
    this.#handle.emit("ephemeral-message", {
      handle: this.#handle,
      senderId,
      message: contents
    });
    this.#peers.forEach((peerId) => {
      if (peerId === senderId)
        return;
      this.emit("message", {
        ...message,
        targetId: peerId
      });
    });
  }
  receiveSyncMessage(message) {
    if (message.documentId !== this.#handle.documentId)
      throw new Error(`channelId doesn't match documentId`);
    if (!this.#handle.inState([READY, REQUESTING, UNAVAILABLE])) {
      this.#pendingSyncMessages.push({ message, received: /* @__PURE__ */ new Date() });
      return;
    }
    this.#processAllPendingSyncMessages();
    this.#processSyncMessage(message);
  }
  #processSyncMessage(message) {
    if (isRequestMessage(message)) {
      this.#peerDocumentStatuses[message.senderId] = "wants";
    }
    this.#checkDocUnavailable();
    if (A3.decodeSyncMessage(message.data).heads.length > 0) {
      this.#peerDocumentStatuses[message.senderId] = "has";
    }
    this.#withSyncState(message.senderId, (syncState) => {
      this.#handle.update((doc) => {
        const [newDoc, newSyncState] = A3.receiveSyncMessage(doc, syncState, message.data);
        this.#setSyncState(message.senderId, newSyncState);
        this.#sendSyncMessage(message.senderId, doc);
        return newDoc;
      });
      this.#checkDocUnavailable();
    });
  }
  #checkDocUnavailable() {
    if (this.#syncStarted && this.#handle.inState([REQUESTING]) && this.#peers.every((peerId) => this.#peerDocumentStatuses[peerId] === "unavailable" || this.#peerDocumentStatuses[peerId] === "wants")) {
      this.#peers.filter((peerId) => this.#peerDocumentStatuses[peerId] === "wants").forEach((peerId) => {
        const message = {
          type: "doc-unavailable",
          documentId: this.#handle.documentId,
          targetId: peerId
        };
        this.emit("message", message);
      });
      this.#handle.unavailable();
    }
  }
  #processAllPendingSyncMessages() {
    for (const message of this.#pendingSyncMessages) {
      this.#processSyncMessage(message.message);
    }
    this.#pendingSyncMessages = [];
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/synchronizer/CollectionSynchronizer.js
var log2 = debug6("automerge-repo:collectionsync");
var CollectionSynchronizer = class extends Synchronizer {
  repo;
  /** The set of peers we are connected with */
  #peers = /* @__PURE__ */ new Set();
  /** A map of documentIds to their synchronizers */
  #docSynchronizers = {};
  /** Used to determine if the document is know to the Collection and a synchronizer exists or is being set up */
  #docSetUp = {};
  constructor(repo) {
    super();
    this.repo = repo;
  }
  /** Returns a synchronizer for the given document, creating one if it doesn't already exist.  */
  #fetchDocSynchronizer(documentId) {
    if (!this.#docSynchronizers[documentId]) {
      const handle = this.repo.find(stringifyAutomergeUrl({ documentId }));
      this.#docSynchronizers[documentId] = this.#initDocSynchronizer(handle);
    }
    return this.#docSynchronizers[documentId];
  }
  /** Creates a new docSynchronizer and sets it up to propagate messages */
  #initDocSynchronizer(handle) {
    const docSynchronizer = new DocSynchronizer({
      handle,
      onLoadSyncState: async (peerId) => {
        if (!this.repo.storageSubsystem) {
          return;
        }
        const { storageId, isEphemeral } = this.repo.peerMetadataByPeerId[peerId] || {};
        if (!storageId || isEphemeral) {
          return;
        }
        return this.repo.storageSubsystem.loadSyncState(handle.documentId, storageId);
      }
    });
    docSynchronizer.on("message", (event) => this.emit("message", event));
    docSynchronizer.on("open-doc", (event) => this.emit("open-doc", event));
    docSynchronizer.on("sync-state", (event) => this.emit("sync-state", event));
    return docSynchronizer;
  }
  /** returns an array of peerIds that we share this document generously with */
  async #documentGenerousPeers(documentId) {
    const peers = Array.from(this.#peers);
    const generousPeers = [];
    for (const peerId of peers) {
      const okToShare = await this.repo.sharePolicy(peerId, documentId);
      if (okToShare)
        generousPeers.push(peerId);
    }
    return generousPeers;
  }
  // PUBLIC
  /**
   * When we receive a sync message for a document we haven't got in memory, we
   * register it with the repo and start synchronizing
   */
  async receiveMessage(message) {
    log2(`onSyncMessage: ${message.senderId}, ${message.documentId}, ${"data" in message ? message.data.byteLength + "bytes" : ""}`);
    const documentId = message.documentId;
    if (!documentId) {
      throw new Error("received a message with an invalid documentId");
    }
    this.#docSetUp[documentId] = true;
    const docSynchronizer = this.#fetchDocSynchronizer(documentId);
    docSynchronizer.receiveMessage(message);
    const peers = await this.#documentGenerousPeers(documentId);
    docSynchronizer.beginSync(peers.filter((peerId) => !docSynchronizer.hasPeer(peerId)));
  }
  /**
   * Starts synchronizing the given document with all peers that we share it generously with.
   */
  addDocument(documentId) {
    if (this.#docSetUp[documentId]) {
      return;
    }
    const docSynchronizer = this.#fetchDocSynchronizer(documentId);
    void this.#documentGenerousPeers(documentId).then((peers) => {
      docSynchronizer.beginSync(peers);
    });
  }
  // TODO: implement this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeDocument(documentId) {
    throw new Error("not implemented");
  }
  /** Adds a peer and maybe starts synchronizing with them */
  addPeer(peerId) {
    log2(`adding ${peerId} & synchronizing with them`);
    if (this.#peers.has(peerId)) {
      return;
    }
    this.#peers.add(peerId);
    for (const docSynchronizer of Object.values(this.#docSynchronizers)) {
      const { documentId } = docSynchronizer;
      void this.repo.sharePolicy(peerId, documentId).then((okToShare) => {
        if (okToShare)
          docSynchronizer.beginSync([peerId]);
      });
    }
  }
  /** Removes a peer and stops synchronizing with them */
  removePeer(peerId) {
    log2(`removing peer ${peerId}`);
    this.#peers.delete(peerId);
    for (const docSynchronizer of Object.values(this.#docSynchronizers)) {
      docSynchronizer.endSync(peerId);
    }
  }
  /** Returns a list of all connected peer ids */
  get peers() {
    return Array.from(this.#peers);
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/Repo.js
var Repo = class extends EventEmitter5 {
  #log;
  /** @hidden */
  networkSubsystem;
  /** @hidden */
  storageSubsystem;
  /** The debounce rate is adjustable on the repo. */
  /** @hidden */
  saveDebounceRate = 100;
  #handleCache = {};
  #synchronizer;
  /** By default, we share generously with all peers. */
  /** @hidden */
  sharePolicy = async () => true;
  /** maps peer id to to persistence information (storageId, isEphemeral), access by collection synchronizer  */
  /** @hidden */
  peerMetadataByPeerId = {};
  #remoteHeadsSubscriptions = new RemoteHeadsSubscriptions();
  #remoteHeadsGossipingEnabled = false;
  constructor({ storage, network = [], peerId, sharePolicy, isEphemeral = storage === void 0, enableRemoteHeadsGossiping = false } = {}) {
    super();
    this.#remoteHeadsGossipingEnabled = enableRemoteHeadsGossiping;
    this.#log = debug7(`automerge-repo:repo`);
    this.sharePolicy = sharePolicy ?? this.sharePolicy;
    this.on("document", async ({ handle, isNew }) => {
      if (storageSubsystem) {
        const saveFn = ({ handle: handle2, doc }) => {
          void storageSubsystem.saveDoc(handle2.documentId, doc);
        };
        handle.on("heads-changed", throttle(saveFn, this.saveDebounceRate));
        if (isNew) {
          await storageSubsystem.saveDoc(handle.documentId, handle.docSync());
        } else {
          const loadedDoc = await storageSubsystem.loadDoc(handle.documentId);
          if (loadedDoc) {
            handle.update(() => loadedDoc);
          }
        }
      }
      handle.on("unavailable", () => {
        this.#log("document unavailable", { documentId: handle.documentId });
        this.emit("unavailable-document", {
          documentId: handle.documentId
        });
      });
      if (this.networkSubsystem.isReady()) {
        handle.request();
      } else {
        handle.awaitNetwork();
        this.networkSubsystem.whenReady().then(() => {
          handle.networkReady();
        }).catch((err) => {
          this.#log("error waiting for network", { err });
        });
      }
      this.#synchronizer.addDocument(handle.documentId);
    });
    this.on("delete-document", ({ documentId }) => {
      if (storageSubsystem) {
        storageSubsystem.removeDoc(documentId).catch((err) => {
          this.#log("error deleting document", { documentId, err });
        });
      }
    });
    this.#synchronizer = new CollectionSynchronizer(this);
    this.#synchronizer.on("message", (message) => {
      this.#log(`sending ${message.type} message to ${message.targetId}`);
      networkSubsystem.send(message);
    });
    if (this.#remoteHeadsGossipingEnabled) {
      this.#synchronizer.on("open-doc", ({ peerId: peerId2, documentId }) => {
        this.#remoteHeadsSubscriptions.subscribePeerToDoc(peerId2, documentId);
      });
    }
    const storageSubsystem = storage ? new StorageSubsystem(storage) : void 0;
    this.storageSubsystem = storageSubsystem;
    const myPeerMetadata = (async () => ({
      storageId: await storageSubsystem?.id(),
      isEphemeral
    }))();
    const networkSubsystem = new NetworkSubsystem(network, peerId, myPeerMetadata);
    this.networkSubsystem = networkSubsystem;
    networkSubsystem.on("peer", async ({ peerId: peerId2, peerMetadata }) => {
      this.#log("peer connected", { peerId: peerId2 });
      if (peerMetadata) {
        this.peerMetadataByPeerId[peerId2] = { ...peerMetadata };
      }
      this.sharePolicy(peerId2).then((shouldShare) => {
        if (shouldShare && this.#remoteHeadsGossipingEnabled) {
          this.#remoteHeadsSubscriptions.addGenerousPeer(peerId2);
        }
      }).catch((err) => {
        console.log("error in share policy", { err });
      });
      this.#synchronizer.addPeer(peerId2);
    });
    networkSubsystem.on("peer-disconnected", ({ peerId: peerId2 }) => {
      this.#synchronizer.removePeer(peerId2);
      this.#remoteHeadsSubscriptions.removePeer(peerId2);
    });
    networkSubsystem.on("message", async (msg) => {
      this.#receiveMessage(msg);
    });
    this.#synchronizer.on("sync-state", (message) => {
      this.#saveSyncState(message);
      const handle = this.#handleCache[message.documentId];
      const { storageId } = this.peerMetadataByPeerId[message.peerId] || {};
      if (!storageId) {
        return;
      }
      const heads = handle.getRemoteHeads(storageId);
      const haveHeadsChanged = message.syncState.theirHeads && (!heads || !headsAreSame(heads, message.syncState.theirHeads));
      if (haveHeadsChanged && message.syncState.theirHeads) {
        handle.setRemoteHeads(storageId, message.syncState.theirHeads);
        if (storageId && this.#remoteHeadsGossipingEnabled) {
          this.#remoteHeadsSubscriptions.handleImmediateRemoteHeadsChanged(message.documentId, storageId, message.syncState.theirHeads);
        }
      }
    });
    if (this.#remoteHeadsGossipingEnabled) {
      this.#remoteHeadsSubscriptions.on("notify-remote-heads", (message) => {
        this.networkSubsystem.send({
          type: "remote-heads-changed",
          targetId: message.targetId,
          documentId: message.documentId,
          newHeads: {
            [message.storageId]: {
              heads: message.heads,
              timestamp: message.timestamp
            }
          }
        });
      });
      this.#remoteHeadsSubscriptions.on("change-remote-subs", (message) => {
        this.#log("change-remote-subs", message);
        for (const peer of message.peers) {
          this.networkSubsystem.send({
            type: "remote-subscription-change",
            targetId: peer,
            add: message.add,
            remove: message.remove
          });
        }
      });
      this.#remoteHeadsSubscriptions.on("remote-heads-changed", (message) => {
        const handle = this.#handleCache[message.documentId];
        handle.setRemoteHeads(message.storageId, message.remoteHeads);
      });
    }
  }
  #receiveMessage(message) {
    switch (message.type) {
      case "remote-subscription-change":
        if (this.#remoteHeadsGossipingEnabled) {
          this.#remoteHeadsSubscriptions.handleControlMessage(message);
        }
        break;
      case "remote-heads-changed":
        if (this.#remoteHeadsGossipingEnabled) {
          this.#remoteHeadsSubscriptions.handleRemoteHeads(message);
        }
        break;
      case "sync":
      case "request":
      case "ephemeral":
      case "doc-unavailable":
        this.#synchronizer.receiveMessage(message).catch((err) => {
          console.log("error receiving message", { err });
        });
    }
  }
  #throttledSaveSyncStateHandlers = {};
  /** saves sync state throttled per storage id, if a peer doesn't have a storage id it's sync state is not persisted */
  #saveSyncState(payload) {
    if (!this.storageSubsystem) {
      return;
    }
    const { storageId, isEphemeral } = this.peerMetadataByPeerId[payload.peerId] || {};
    if (!storageId || isEphemeral) {
      return;
    }
    let handler = this.#throttledSaveSyncStateHandlers[storageId];
    if (!handler) {
      handler = this.#throttledSaveSyncStateHandlers[storageId] = throttle(({ documentId, syncState }) => {
        void this.storageSubsystem.saveSyncState(documentId, storageId, syncState);
      }, this.saveDebounceRate);
    }
    handler(payload);
  }
  /** Returns an existing handle if we have it; creates one otherwise. */
  #getHandle({ documentId, isNew, initialValue }) {
    if (this.#handleCache[documentId])
      return this.#handleCache[documentId];
    if (!documentId)
      throw new Error(`Invalid documentId ${documentId}`);
    const handle = new DocHandle(documentId, { isNew, initialValue });
    this.#handleCache[documentId] = handle;
    return handle;
  }
  /** Returns all the handles we have cached. */
  get handles() {
    return this.#handleCache;
  }
  /** Returns a list of all connected peer ids */
  get peers() {
    return this.#synchronizer.peers;
  }
  getStorageIdOfPeer(peerId) {
    return this.peerMetadataByPeerId[peerId]?.storageId;
  }
  /**
   * Creates a new document and returns a handle to it. The initial value of the document is an
   * empty object `{}` unless an initial value is provided. Its documentId is generated by the
   * system. we emit a `document` event to advertise interest in the document.
   */
  create(initialValue) {
    const { documentId } = parseAutomergeUrl(generateAutomergeUrl());
    const handle = this.#getHandle({
      documentId,
      isNew: true,
      initialValue
    });
    this.emit("document", { handle, isNew: true });
    return handle;
  }
  /** Create a new DocHandle by cloning the history of an existing DocHandle.
   *
   * @param clonedHandle - The handle to clone
   *
   * @remarks This is a wrapper around the `clone` function in the Automerge library.
   * The new `DocHandle` will have a new URL but will share history with the original,
   * which means that changes made to the cloned handle can be sensibly merged back
   * into the original.
   *
   * Any peers this `Repo` is connected to for whom `sharePolicy` returns `true` will
   * be notified of the newly created DocHandle.
   *
   * @throws if the cloned handle is not yet ready or if
   * `clonedHandle.docSync()` returns `undefined` (i.e. the handle is unavailable).
   */
  clone(clonedHandle) {
    if (!clonedHandle.isReady()) {
      throw new Error(`Cloned handle is not yet in ready state.
        (Try await handle.waitForReady() first.)`);
    }
    const sourceDoc = clonedHandle.docSync();
    if (!sourceDoc) {
      throw new Error("Cloned handle doesn't have a document.");
    }
    const handle = this.create();
    handle.update(() => {
      return Automerge.clone(sourceDoc);
    });
    return handle;
  }
  /**
   * Retrieves a document by id. It gets data from the local system, but also emits a `document`
   * event to advertise interest in the document.
   */
  find(id) {
    const documentId = interpretAsDocumentId(id);
    if (this.#handleCache[documentId]) {
      if (this.#handleCache[documentId].isUnavailable()) {
        setTimeout(() => {
          this.#handleCache[documentId].emit("unavailable", {
            handle: this.#handleCache[documentId]
          });
        });
      }
      return this.#handleCache[documentId];
    }
    const handle = this.#getHandle({
      documentId,
      isNew: false
    });
    this.emit("document", { handle, isNew: false });
    return handle;
  }
  delete(id) {
    const documentId = interpretAsDocumentId(id);
    const handle = this.#getHandle({ documentId, isNew: false });
    handle.delete();
    delete this.#handleCache[documentId];
    this.emit("delete-document", { documentId });
  }
  /**
   * Exports a document to a binary format.
   * @param id - The url or documentId of the handle to export
   *
   * @returns Promise<Uint8Array | undefined> - A Promise containing the binary document,
   * or undefined if the document is unavailable.
   */
  async export(id) {
    const documentId = interpretAsDocumentId(id);
    const handle = this.#getHandle({ documentId, isNew: false });
    const doc = await handle.doc();
    if (!doc)
      return void 0;
    return Automerge.save(doc);
  }
  /**
   * Imports document binary into the repo.
   * @param binary - The binary to import
   */
  import(binary) {
    const doc = Automerge.load(binary);
    const handle = this.create();
    handle.update(() => {
      return Automerge.clone(doc);
    });
    return handle;
  }
  subscribeToRemotes = (remotes) => {
    if (this.#remoteHeadsGossipingEnabled) {
      this.#log("subscribeToRemotes", { remotes });
      this.#remoteHeadsSubscriptions.subscribeToRemotes(remotes);
    } else {
      this.#log("WARN: subscribeToRemotes called but remote heads gossiping is not enabled");
    }
  };
  storageId = async () => {
    if (!this.storageSubsystem) {
      return void 0;
    } else {
      return this.storageSubsystem.id();
    }
  };
  /**
   * Writes Documents to a disk.
   * @hidden this API is experimental and may change.
   * @param documents - if provided, only writes the specified documents.
   * @returns Promise<void>
   */
  async flush(documents) {
    if (!this.storageSubsystem) {
      return;
    }
    const handles = documents ? documents.map((id) => this.#handleCache[id]) : Object.values(this.#handleCache);
    await Promise.all(handles.map(async (handle) => {
      const doc = handle.docSync();
      if (!doc) {
        return;
      }
      return this.storageSubsystem.saveDoc(handle.documentId, doc);
    }));
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/network/NetworkAdapter.js
import { EventEmitter as EventEmitter6 } from "eventemitter3";
var NetworkAdapter = class extends EventEmitter6 {
  peerId;
  peerMetadata;
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/storage/StorageAdapter.js
var StorageAdapter = class {
};

// ../../../../node_modules/.pnpm/@automerge+automerge-repo@1.1.12_@swc+core@1.5.7_@swc+helpers@0.5.1__@types+node@22.10.2_typescript@5.7.2/node_modules/@automerge/automerge-repo/dist/index.js
import { Counter, RawString } from "#automerge/next";
import { getChanges, getAllChanges, applyChanges, view, getConflicts } from "#automerge/next";
import { getCursor, getCursorPosition, splice, updateText, insertAt, deleteAt, mark, unmark } from "#automerge/next";

// src/automerge-repo.ts
import * as Uuid3 from "uuid";
var generateAutomergeUrl2 = () => {
  const documentId = Uuid3.v4(null, new Uint8Array(16));
  return stringifyAutomergeUrl({ documentId });
};
export {
  Counter,
  DocHandle,
  NetworkAdapter,
  RawString,
  Repo,
  StorageAdapter,
  applyChanges,
  cbor_exports as cbor,
  deleteAt,
  generateAutomergeUrl2 as generateAutomergeUrl,
  getAllChanges,
  getChanges,
  getConflicts,
  getCursor,
  getCursorPosition,
  insertAt,
  interpretAsDocumentId,
  isRepoMessage,
  isValidAutomergeUrl,
  mark,
  parseAutomergeUrl,
  splice,
  stringifyAutomergeUrl,
  unmark,
  updateText,
  view
};
